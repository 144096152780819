import {
    Body,
    Container,
    Head,
    Html,
    Img,
    Preview,
    Text,
} from "@react-email/components";
import * as React from "react";

interface DeleteAccountAdminProps {
    userInfo: {
        name: string;
        email: string;
    };
    reason: string;
    freeInput: string;
}

export const DeleteAccountAdmin = ({
    userInfo,
    reason,
    freeInput,
}: DeleteAccountAdminProps) => (
    <Html>
        <Head />
        <Preview>
            User deleted account - SocialFlick
        </Preview>
        <Body style={main}>
            <Container style={container}>
                <Img
                    src={`https://app.socialflick.io/logo/logo-vertical-black.png`}
                    width={"50%"}
                    alt="SocialFlick"
                    style={logo}
                />
                <Text style={paragraph}>User has deleted their account:</Text>
                <Text style={paragraph}>Username: {userInfo.name}</Text>
                <Text style={paragraph}>Email: {userInfo.email}</Text>
                <Text style={paragraph}>Reason: {reason}</Text>
                <Text style={paragraph}>Input: {freeInput}</Text>
            </Container>
        </Body>
    </Html>
);

DeleteAccountAdmin.PreviewProps = {
    userInfo: {
        name: '',
        email: ''
    },
    reason: '',
    freeInput: '',
} as DeleteAccountAdminProps;

export default DeleteAccountAdmin;

const main = {
    backgroundColor: "#ffffff",
    fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
};

const container = {
    margin: "0 auto",
    padding: "20px 0 48px",
};

const logo = {
    margin: "0 auto",
};

const paragraph = {
    fontSize: "16px",
    lineHeight: "26px",
};
