import { breakpoints } from './constants';

// Function to check if the window is in mobile mode
export const isMobile = () => window.innerWidth <= breakpoints.mobile;

// Function to handle window resize
export const handleResize = (setIsMobile) => {
    setIsMobile(isMobile());
};

// Debounce function to limit the number of times a function is called
export const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};
