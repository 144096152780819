import React from 'react';
import AuthForm from '../../components/Auth/AuthForm';
import { Link } from 'react-router-dom';

const SignupPage = ({ checkLoginStatus }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="w-full bg-gray-800 shadow-lg z-10 flex justify-between items-end px-8 py-4">
        <a href="https://socialflick.io">
          <img src="./logo/logo-horizontal.png" className="w-[100px]" alt="logo" />
        </a>
        <p className="text-gray-200 text-right w-[60%] sm:w-auto">
          Already have an account? <Link to="/login" className="text-blue-400">Log in</Link>
        </p>
      </div>

      <div className='flex flex-col md:flex-row lg:flex-row items-center justify-center flex-1 gap-5 lg:gap-x-5'>
        <AuthForm isLogin={false} checkLoginStatus={checkLoginStatus} />
      </div>
    </div>
  );
}


export default SignupPage;