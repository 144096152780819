import Saasupport from 'saasupport'
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
const SAASUPPORT_API = process.env.REACT_APP_SAASUPPORT_API;

const Support = ({ value }) => {
    const [userInfo, setUserInfo] = useState(null)
    const hasRunOnce = useRef(false);

    useEffect(() => {
        if (!hasRunOnce.current) {
            getUser();
            hasRunOnce.current = true;
        }
    }, []);

    const getUser = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/getUser', {
                withCredentials: true,
            });

            setUserInfo(response.data);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            {userInfo && (
                <Saasupport
                    userId={userInfo._id}
                    apiKey={`${SAASUPPORT_API}`}
                    showSuggestFeature={true}
                    showSuggestions={true}
                    showGuide={{ show: true, title: 'Help Center', description: 'Check out our help center for', link: 'https://www.socialflick.io/help-center', imageUrl: 'guideImageUrl' }}
                />
            )}
        </>
    )
}

export default Support