import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './VerticalNavbar.css'; // Create a CSS file for styling
import { FaPlus } from 'react-icons/fa';
import { BiSolidDashboard, BiHistory, BiSolidHelpCircle } from 'react-icons/bi';
import { IoShareSocialSharp } from 'react-icons/io5';
import { IoIosSettings } from 'react-icons/io';
import { MdArrowBackIosNew, MdArrowForwardIos, MdOutlineDriveFolderUpload, MdOutlineCalendarMonth } from 'react-icons/md';
import { TbTemplate } from "react-icons/tb";
import { motion } from "framer-motion";


const VerticalNavbar = () => {
  const [isExpanded, setIsExpanded] = useState(() => {
    const storedIsExpanded = localStorage.getItem('navbarIsExpanded');
    return storedIsExpanded !== null ? JSON.parse(storedIsExpanded) : true;
  });

  const location = useLocation(); // Get the current location from React Router
  const [currentPath, setCurrentPath] = useState(null)
  const hasRunOnceRef = useRef(false);

  useEffect(() => {
    if (!hasRunOnceRef.current && location.pathname !== '') {
      setCurrentPath(location.pathname)
    }
  }, [location])

  useEffect(() => {
    localStorage.setItem('navbarIsExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);


  const toggleNavbar = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    const storedIsExpanded = localStorage.getItem('navbarIsExpanded');
    if (storedIsExpanded === null) {
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        setIsExpanded(screenWidth > 768);
      };

      handleResize();

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const isActive = (currentPath, path) => {
    return currentPath === path ? 'active' : '';
  };

  const sidebarVariants = {
    expanded: {
      width: 230,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 20,
      },
    },
    collapsed: {
      width: 80,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 20,
      },
    },
  };


  return (
    <motion.div
      className={`vertical-menu relative shadow-lg shadow-blue-800/70 ${isExpanded ? 'min-w-[230px] w-[230px] p-[20px]' : 'w-[80px]'} bg-gray-200 h-screen`}
      variants={sidebarVariants}
      animate={isExpanded ? 'expanded' : 'collapsed'}
      initial={false}>

      <motion.div layout className={`relative py-5 ${isExpanded ? '' : 'pt-[100px]'}`}>
        <Link to="/dashboard" className="flex ">
          {isExpanded ? (
            <img src={`${process.env.PUBLIC_URL}/logo/logo-horizontal.png`} alt="Logo" />
          ) : (
            <img src={`${process.env.PUBLIC_URL}/logo/favicon-nobg.png`} alt="Logo" />
          )
          }
        </Link>

      </motion.div>
      <motion.div layout
        className={`px-2 py-1 text-white absolute right-0 top-10`}
        onClick={toggleNavbar}
      >
        {isExpanded ? (
          <MdArrowBackIosNew size={30} color="white" className="opacity-50 hover:border-gray-300 border-2 border-transparent rounded-xl p-1" />
        ) : (
          <MdArrowForwardIos size={30} color="white" className="opacity-50 hover:border-gray-300 border-2 border-transparent rounded-xl p-1" />
        )}
      </motion.div>

      <motion.div layout className={`${isExpanded ? 'pb-10' : 'mx-auto'} `}>
        <div
          className={`${isExpanded ? 'py-3 px-1' : 'p-3'
            } text-sm text-white bg-gradient-to-r hover:from-blue-600 hover:to-blue-800 mx-auto bg-blue-600 font-bold rounded-full cursor-pointer flex items-center w-[80%] justify-center transition-all duration-300`}
        >
          <Link to="/dashboard/upload" className="flex">
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Upload
              </motion.span>)}
            <FaPlus size={20} color="white" className={`${isExpanded ? 'ml-3' : ''}`} />
          </Link>
        </div>
      </motion.div>



      {/* OVERVIEW MENU */}

      <div className={`flex flex-col justify-center`}>
        <p className={`text-sm font-semibold mb-1 ${isExpanded ? '' : 'invisible w-0'}`}>OVERVIEW</p>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} ${isActive(currentPath, '/dashboard')} group relative hover:bg-gray-700 p-1 rounded-md`}>
          <Link to="/dashboard" className={`nav-link flex items-center gap-2  ${!isExpanded ? 'group' : ''}`}>
            <BiSolidDashboard size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Dashboard
              </motion.span>
            )}
          </Link>
        </div>
      </div>


      {/* CONTENT MENU */}
      <div className={`flex flex-col justify-center ${isExpanded ? 'mt-10' : 'mt-1'}`}>
        <p className={`text-sm font-semibold mb-1 ${isExpanded ? '' : 'hidden w-0'}`}>CONTENT</p>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2 ${isActive(currentPath, '/dashboard/upload')}  `}>
          <Link to="/dashboard/upload" className={`nav-link flex items-center gap-2 ${!isExpanded ? 'group' : ''}`}>
            <MdOutlineDriveFolderUpload size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Upload
              </motion.span>
            )}          </Link>
        </div>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md  mb-2 ${isActive(currentPath, '/dashboard/schedule')} `}>
          <Link to="/dashboard/schedule" className={`nav-link flex items-center gap-2 ${!isExpanded ? 'group' : ''}`}>
            <MdOutlineCalendarMonth size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Calendar
              </motion.span>
            )}          </Link>
        </div>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md  mb-2 ${isActive(currentPath, '/dashboard/templates')}`}>
          <Link to="/dashboard/templates" className={`nav-link flex items-center gap-2  ${!isExpanded ? 'group' : ''}`}>
            <TbTemplate size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Templates
              </motion.span>
            )}          </Link>
        </div>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md  mb-2 ${isActive(currentPath, '/dashboard/posts')}`}>
          <Link to="/dashboard/posts" className={`nav-link flex items-center gap-2  ${!isExpanded ? 'group' : ''}`}>
            <BiHistory size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Posts
              </motion.span>
            )}
          </Link>
        </div>

      </div>

      {/* CONFIG MENU */}
      <div className={`flex flex-col justify-end mb-[50px] flex-1`}>
        <p className={`text-sm font-semibold mb-1 ${isExpanded ? '' : 'invisible w-0'}`}>CONFIG</p>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md  mb-2  ${isActive(currentPath, '/dashboard/socials')}`}>
          <Link to="/dashboard/socials" className={`nav-link flex items-center gap-2 ${!isExpanded ? 'group' : ''}`}>
            <IoShareSocialSharp size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Socials
              </motion.span>
            )}
          </Link>
        </div>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md  mb-2 ${isActive(currentPath, '/dashboard/settings')}`}>
          <Link to="/dashboard/settings" className={`nav-link flex items-center gap-2  ${!isExpanded ? 'group' : ''}`}>
            <IoIosSettings size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Settings
              </motion.span>
            )}          </Link>
        </div>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2`}>
          <Link to="/dashboard/support" className={`nav-link flex items-center gap-2`}>
            <BiSolidHelpCircle size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Support
              </motion.span>
            )}
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default VerticalNavbar;