import React, { useEffect, useRef, useState } from 'react';
import Tabs from '../../Navigation/MenuTabs/Tabs';
import { FaComment, FaEye, FaInstagram, FaTiktok } from 'react-icons/fa';
import { LuRectangleVertical, LuRectangleHorizontal } from "react-icons/lu";
import UploadArea from './UploadArea';
import { CiImageOn } from "react-icons/ci";
import ThumbnailSelector from '../Thumbnail/Thumbnailselector';
import { getFileType } from '../../../utils/getFileType';

const VideoPreview = ({ draftVideo, draftThumb, onFileSelect, uploadType }) => {
  const videoRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState('preview');
  const [activePreviewMenu, setActivePreviewMenu] = useState('instagram');
  const [activeSettingsTab, setActiveSettingsTab] = useState('vertical');
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [firebasePath, setFirebasePath] = useState(draftVideo);
  const [firebaseThumbnail, setFirebaseThumbnail] = useState(draftThumb);
  const [isModalOpen, setModalOpen] = useState(false);

  const siteMenu = [
    { id: 'preview', label: 'Preview', icon: <FaEye /> },
    ...(firebasePath && uploadType === 'video' ? [{ id: 'thumbnail', label: 'Thumbnail', icon: <CiImageOn /> }] : []),
    { id: 'comment', label: 'Comments', icon: <FaComment /> },
  ];

  const previewSocialsMenu = [
    { id: 'instagram', label: '', icon: <FaInstagram color='#cd486b' size={20} /> },
    { id: 'tiktok', label: '', icon: <FaTiktok color='black' size={20} /> },
  ];

  const previewSettingsMenu = [
    { id: 'vertical', label: 'Vertical', icon: <LuRectangleVertical color='red' size={20} /> },
    { id: 'horizontal', label: 'Horizontal', icon: <LuRectangleHorizontal color='black' size={20} /> },
  ];

  useEffect(() => {
    if (draftVideo) {
      setFirebasePath(draftVideo);
    }
  }, [draftVideo]);

  useEffect(() => {
    if (draftThumb) {
      setFirebaseThumbnail(draftThumb);
    }
  }, [draftThumb]);


  const handleSiteMenuChange = (newTab) => {
    setActiveMenu(newTab);
  };

  const handlePreviewMenuChange = (newTab) => {
    setActivePreviewMenu(newTab);
  };

  const handlePreviewSettingsChange = (newTab) => {
    setActiveSettingsTab(newTab);
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const handleFileSelect = (firebasePath) => {
    setFirebasePath(firebasePath);
    onFileSelect(firebasePath, firebaseThumbnail)
  };

  const handleThumbnailSelect = (img) => {
    setFirebaseThumbnail(img.imageUrl);
    onFileSelect(firebasePath, img.imageUrl)
  };

  const toggleModal = () => setModalOpen(!isModalOpen);

  return (
    <div className="video-preview dashboard-section h-full flex flex-col">
      <div>
        <Tabs tabs={siteMenu} onTabChange={handleSiteMenuChange} activeTab={activeMenu} main={true} />
      </div>

      {activeMenu === 'preview' && (
        <div className="pt-5 h-full">
          {firebasePath ? (
            <div className="flex flex-col sm:flex-row">
              <div className={`relative flex justify-center ${activeSettingsTab === 'vertical' ? 'max-h-[500px]' : 'w-[400px] md:w-[600px] h-[300px]'}`}>
                {getFileType(uploadType) === 'video' ? (
                  <video
                    ref={videoRef}
                    onLoadedMetadata={handleVideoLoad}
                    controls
                    preload="auto"
                    className={`rounded-md border ${activeSettingsTab === 'vertical' ? 'max-w-[300px] sm:w-[300px]' : 'w-[400px] md:w-[600px]'}`}
                  >
                    <source src={firebasePath} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={firebasePath}
                    className='cursor-pointer'
                    alt="uploaded media"
                    onClick={toggleModal} // Open modal when clicked
                  />)}

                {activeSettingsTab === 'vertical' && activePreviewMenu && videoLoaded && (
                  <img src={`../socials/${activePreviewMenu}.svg`} alt="Overlay SVG" className="overlay-svg absolute top-0 left-2 opacity-30 h-full pointer-events-none" />
                )}
                {getFileType(uploadType) === 'video' && (
                  <div className='flex flex-col justify-between'>
                    {activeSettingsTab === 'vertical' && (
                      <div className="">
                        <Tabs tabs={previewSocialsMenu} onTabChange={handlePreviewMenuChange} activeTab={activePreviewMenu} main={false} useResponsiveDropdown={false} />
                      </div>
                    )}
                    <div className="">
                      <Tabs tabs={previewSettingsMenu} onTabChange={handlePreviewSettingsChange} activeTab={activeSettingsTab} main={false} useResponsiveDropdown={false} />
                    </div>
                  </div>
                )}
              </div>

            </div>
          ) : (
            <UploadArea onFileSelect={handleFileSelect} uploadType={uploadType} />
          )}
        </div>
      )
      }

      {
        firebasePath && uploadType === 'video' && (
          <div>
            <ThumbnailSelector videoName={firebasePath} onThumbnailSelect={handleThumbnailSelect} draftThumbnail={firebaseThumbnail} isVisible={activeMenu === 'thumbnail' && uploadType === 'video'} />
          </div>
        )
      }


      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={toggleModal} // Close modal when clicked outside
        >
          <img
            src={firebasePath}
            alt="enlarged media"
            style={{ maxWidth: '90%', maxHeight: '90%' }} // Limit to the screen size
          />
        </div>
      )}
    </div >
  );
};

export default VideoPreview;
