import {
    Body,
    Container,
    Head,
    Html,
    Img,
    Preview,
    Text,
    Section,
    Button
} from "@react-email/components";
import * as React from "react";

interface VideoErrorUploadProps {
    upload: {
        title: string;
        description: string;
        thumbnail: string;
        uploadTo: string;
    };
}

export const VideoErrorUpload = ({
    upload,
}: VideoErrorUploadProps) => (
    <Html>
        <Head />
        <Preview>
            Your video couldn't be uploaded to SocialFlick. Check details and retry now!
        </Preview>
        <Body style={main}>

            <Container style={container}>
                <Img
                    src={`https://app.socialflick.io/logo/logo-vertical-black.png`}
                    width={"50%"}
                    alt="SocialFlick"
                    style={logo}
                />
                <Text style={paragraph}>
                    Unfortunately, your video failed to upload. Please try <a href="https://app.socialflick.io/dashboard/socials" style={link}>re-connecting your social account</a> and re-upload.
                </Text>
                <div style={box}>
                    <Img
                        src={upload.thumbnail}
                        alt="Video Thumbnail"
                        style={thumbnail}
                    />
                    <Text style={title}>{upload.title}</Text>
                    <Text style={description}>{upload.description}</Text>
                    <Text style={uploadTo}>{upload.uploadTo}</Text>
                </div>

                <Section style={btnContainer}>
                    <Button style={button} href={'https://app.socialflick.io/dashboard/upload'}>
                        Upload Again
                    </Button>
                </Section>
            </Container>
        </Body>
    </Html>
);

VideoErrorUpload.PreviewProps = {
    upload: {
        title: '',
        description: '',
        thumbnail: '',
        uploadTo: '',
    },
} as VideoErrorUploadProps;

export default VideoErrorUpload;

const main = {
    backgroundColor: "#ffffff",
    fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
};

const container = {
    margin: "0 auto",
    padding: "20px 0 48px",
};

const logo = {
    margin: "0 auto",
    display: "block",
};
const box = {
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    padding: "20px",
    textAlign: "left" as const,
    width: "80%",
    margin: "20px auto",
};

const thumbnail = {
    width: "100%",
    maxHeight: "200px",
    objectFit: "cover" as const,
    borderRadius: "8px",
    marginBottom: "10px",
};

const title = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
};

const description = {
    fontSize: "16px",
    marginBottom: "10px",
};
const link = {
    color: "#5F51E8",
    textDecoration: "underline",
};
const uploadTo = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
};

const paragraph = {
    fontSize: "16px",
    lineHeight: "26px",
    textAlign: "center" as const,
};

const btnContainer = {
    textAlign: "center" as const,
};

const button = {
    backgroundColor: "#5F51E8",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "16px",
    textDecoration: "none",
    textAlign: "center" as const,
    display: "block",
    padding: "12px",
    width: "50%",
    margin: "0 auto",
};
