import { useState, useEffect, useRef } from "react";
import axios from 'axios';

//buttons
import { Modal } from '../../../components/Modal/Modal';
import Breadcrumbs from "../../../components/Tools/Breadcrumbs";
import DropdownButton from "../../../components/Tools/DropdownButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { LuRefreshCcw } from "react-icons/lu";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Socials = ({ value }) => {
  const [userSocials, setUserSocials] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const hasRunOnceRef = useRef(false)

  const closeModal = () => {
    setOpenModal(false);
  };
  const openModalHandler = () => {
    setOpenModal(true);
  };


  useEffect(() => {
    if (!hasRunOnceRef.current) {
      hasRunOnceRef.current = true;

      (async () => {
        try {
          await getUsersSocials();
        } catch (error) {
          console.error('An error occurred:', error);
        }
      })();
    }

  }, []);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('socialsChannel');

    broadcastChannel.onmessage = event => {
      if (event.data === 'callbackLogin') {
        getUsersSocials();
      }
    };
  }, []);


  async function getUsersSocials() {
    try {
      const response = await axios.get(BACKEND_API_URL + '/api/auth/user/social_media_tokens', {
        withCredentials: true,
      });
      setUserSocials(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={`${value ?? 'dashboard-container'}`}>

      {!value && (
        <div className="mb-3">
          <Breadcrumbs />
          <p className="text-sm italic">Connect to your social medias to start uploading videos...</p>
        </div>
      )}

      <div className="flex">
        <ShowLoggedInSocials userSocials={userSocials} openModalHandler={openModalHandler} setUserSocials={setUserSocials} />
      </div>
      {openModal && (
        <div className='flex-col items-center border-t-2 pt-3 absolute'>
          <Modal open={openModal} closeModal={closeModal} type={'ConnectSocialsModal'} otherInfo={'hashtag'} />
        </div>
      )}
    </div>
  )
}

const ShowLoggedInSocials = ({ userSocials, openModalHandler, setUserSocials }) => {
  const onDelete = async (id) => {

    try {
      const response = await axios.delete(BACKEND_API_URL + '/api/auth/user/social/logout/' + id, {
        withCredentials: true,
      });
      const newUserSocials = userSocials.filter((social) => social.platform_id !== id);
      setUserSocials(newUserSocials);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  const handleImageError = (event, platform) => {
    event.target.src = `../socials/${platform}-logo.svg`; // Replace with the path to your alternative image
  };

  const handleReauthorize = async (platform) => {
    if (platform === 'youtube') platform = 'google';

    try {
      const response = await axios.post(`${BACKEND_API_URL}/api/${platform}/oauth`, {}, { withCredentials: true });
      window.open(response.data, '_blank');
    } catch (error) {
      console.error('Reauthorization error:', error);
    }
  };
  return (
    <div className="flex flex-wrap w-full">
      <div className='md:hidden w-full sm:w-1/4 md:w-1/4 lg:w-1/6 xl:1/6 min-w-[100px] min-h-[200px] rounded-lg p-5 m-4 text-center relative --theme-colors border-dashed border-4 border-gray-300 flex items-center justify-center transition-all duration-300 hover:border-solid cursor-pointer' onClick={openModalHandler}>
        Connect social
        <br />
        +
      </div>
      {userSocials.map((social, index) => (
        <div key={index} className='w-full sm:w-1/4 md:w-1/4 lg:w-1/6 xl:1/6 min-w-[100px] rounded-lg p-3 m-3 text-center relative --theme-colors --social-cards-shadow'>
          <div className="flex w-full justify-between items-center">
            <img src={`../socials/${social.platform}-logo.svg`} alt={social.platform} className="w-8"></img>


            <DropdownButton label={<BsThreeDotsVertical size={20} />} type={'singleRightItems'} options={[
              { label: 'Refresh account', onClick: () => handleReauthorize(social.platform), icon: <LuRefreshCcw className="text-blue-600 font-bold" /> },
              { label: 'Disconnect account', onClick: () => onDelete(social.platform_id), color: 'red', icon: <FaTrash /> },
              { extra: 'Expires: ' + new Date(social.expiresAt).toLocaleString().replace(/:\d{2}$/, '') }
            ]} />
          </div>

          <div className={`avatar-container max-w-[100px] w-[80%] mx-auto ${social.platform === 'tiktok' && 'rounded-full p-4 bg-black'}`}>
            <img className="avatar-image w-full h-auto rounded-full mx-auto mb-2" src={social.logo} alt={social.username} onError={(e) => handleImageError(e, social.platform)} />
          </div>
          <p className="text-lg font-semibold">{social.username}</p>


        </div>
      ))
      }
      <div className='hidden md:flex w-full sm:w-1/4 md:w-1/4 lg:w-1/6 xl:1/6 min-w-[100px] min-h-[200px] rounded-lg p-5 m-4 text-center relative --theme-colors border-dashed border-4 border-gray-300 flex items-center justify-center transition-all duration-300 hover:border-solid cursor-pointer' onClick={openModalHandler}>
        Connect social
        <br />
        +
      </div>
    </div >
  );
};

export default Socials