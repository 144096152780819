import Breadcrumbs from '../../../components/Tools/Breadcrumbs';
import DraftsBig from '../../../components/Drafts/DraftsBig';
import { useNavigate } from 'react-router-dom';

const History = () => {
  const navigate = useNavigate();  // Use useHistory

  const handleEditDraft = (upload) => {
    navigate(`/dashboard/upload`, { state: { upload } });
  }

  return (
    <div className='flex flex-col gap-2 px-5'>
      <Breadcrumbs />
      <DraftsBig itemsPerPage={12} showFilter={true} onEditDraft={handleEditDraft} />
    </div>
  )
}

export default History