import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Instagram = () => {
    const navigate = useNavigate();
    const hasRunOnceRef = useRef(false)

    useEffect(() => {
        if (!hasRunOnceRef.current) hasRunOnceRef.current = true;
        else return;
        const broadcastChannel = new BroadcastChannel('socialsChannel');

        // Get parameters from url
        const url = window.location.hash;

        const queryString = url.split('#')[1];
        const params = new URLSearchParams(queryString);
        const paramsObject = {};
        params.forEach((value, key) => {
            paramsObject[key] = value;
        });
        //Google Access Token
        if (paramsObject) {
            (async () => {
                await facebook_generate_access_token(paramsObject);
            })();

            async function facebook_generate_access_token(paramsObject) {
                axios.post(BACKEND_API_URL + '/api/instagram/generate_access_token', paramsObject, { withCredentials: true }) //response2 = { "username": "richyvate", "profile_picture_url": "https://scontent-arn2-1.xx.fbcdn.net/v/t51.2885-15/319408352_161441463253715_4935763015952641336_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=7d201b&_nc_ohc=JRVv1_fOKpQAX809Mb2&_nc_ht=scontent-arn2-1.xx&edm=AL-3X8kEAAAA&oh=00_AfBJvOnALzecH907VBj1yUvznqZ3TwShvUScqv_ELwSG_w&oe=654FF264", "id": "17841455574427180" }
                    .then(res => {
                        console.log(res);

                        broadcastChannel.postMessage('callbackLogin');
                        window.close();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            //17841455574427180?fields=name,profile_picture_url,username
        }
    }, [navigate]);


    return (
        <div className='flex justify-center items-center w-full'>
            <LoadingIndicator />
        </div>
    )
}

export default Instagram