import React from 'react';
import { BsEyeFill, BsInfoCircle } from 'react-icons/bs'; // Import the desired icon from react-icons
import Guide from '../Onboarding/Guide';
import Todo from '../ToDo/Todo';

const Info = ({ type, info }) => {
    return (
        <div className="relative inline-block">
            <div className='cursor-pointer'>
                {type === "guide" || type === "todo" ? (
                    <BsEyeFill />
                ) : (
                    <BsInfoCircle className="w-6 h-6 text-gray-600" />
                )}
            </div>
            <div className="absolute bg-gray-200 opacity-1 border p-2  shadow-md hidden  group-hover:block z-10 !w-[250px]">
                {type === "tiktokFeatures" && (
                    <div className='flex flex-col'>
                        <p className="text-sm mb-1"><span className='text-black font-semibold'>Comments:</span>Allow users to leave comments on your video. </p>
                        <p className="text-sm mb-1"><span className='text-black font-semibold'>Stitch:</span> Allow users to blend their videos with yours. </p>
                        <p className="text-sm mb-1"><span className='text-black font-semibold'>Duet:</span> Allow users to collaborate in split-screen videos with your content. </p>
                    </div>
                )}

                {type === "onlyDraftOption" && (
                    <div className='flex flex-col !max-w-[200px] w-max'>
                        <p className="text-sm">Connect or select at least 1 social media to enable the upload button. Or upload to drafts and do it later.</p>
                    </div>
                )}

                {type === "guide" && (
                    <Guide userSettings={info} />
                )}

                {type === "todo" && (
                    <div className='!min-w-[300px]'>
                    <Todo />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Info;
