import React, { useState } from 'react';
import AuthForm from '../../components/Auth/AuthForm';
import { Modal } from '../../components/Modal/Modal';
import { Link } from 'react-router-dom';

const LoginPage = ({ checkLoginStatus }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [recoverEmail, setRecoverEmail] = useState(null);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = (email) => {
        setIsModalOpen(true);
        setRecoverEmail(email);
    }

    return (
        <div className="flex flex-col min-h-screen">
            <div className="w-full bg-gray-800 shadow-lg z-10 flex justify-between items-end px-8 py-4">
                <a href="https://socialflick.io">
                    <img src="./logo/logo-horizontal.png" className="w-[100px]" alt="logo" />
                </a>
                <p className="text-gray-200">
                    No account yet? <Link to="/signup" className="text-blue-400">Sign up</Link>
                </p>
            </div>

            <div className="flex flex-1 items-center justify-center">
                <AuthForm isLogin={true} checkLoginStatus={checkLoginStatus} sendOpenModal={openModal} />
            </div>

            {
                isModalOpen && (
                    <Modal open={isModalOpen} closeModal={closeModal} type={'PasswordModal'} otherInfo={recoverEmail} />
                )
            }
        </div >
    );
}

export default LoginPage;
