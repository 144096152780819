import { useState, useEffect, useRef } from "react";
import axios from 'axios';

// Assets
import './Todo.css';
import Pagination from "../Tools/Pagination";
import { FaCheck, FaTrash } from "react-icons/fa";
import DropdownButton from "../Tools/DropdownButton";
import { BsThreeDotsVertical } from "react-icons/bs";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Todo = () => {
    const hasRunOnceRef = useRef(false);
    const [showTodo, setShowTodo] = useState([]);
    const [showAll, setShowAll] = useState(false);  // Changed from hideChecked to showAll
    const [currentAllItems, setCurrentAllItems] = useState([]);  // New state for current page of all items
    const [uncheckedCurrentItems, setUncheckedCurrentItems] = useState([]);

    useEffect(() => {
        if (!hasRunOnceRef.current) {
            hasRunOnceRef.current = true;
            getTodos();
        }
    }, [])

    const getTodos = () => {
        axios.get(BACKEND_API_URL + '/api/auth/user/todo/all', { withCredentials: true })
            .then(response => {
                if (response.status === 201 && response.data && response.data.userTodo) {
                    const list = response.data.userTodo.list;
                    setShowTodo(list);
                    setUncheckedCurrentItems(list.filter(item => !item.completed));
                    setCurrentAllItems(list);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDeleteTodo = (id) => {
        axios.delete(`${BACKEND_API_URL}/api/auth/user/todo/${id}`, { withCredentials: true })
            .then(response => {
                if (response.status === 200) {
                    const todos = showTodo.filter(todo => todo._id !== id);
                    setShowTodo(todos); // Remove the deleted todo from the state
                    setUncheckedCurrentItems(todos.filter(item => !item.completed));
                    setCurrentAllItems(todos);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);  // Toggle between showing unchecked and all todos
    }

    const handleTodoToggle = (id) => {
        const updatedShowTodo = [...showTodo];
        const index = updatedShowTodo.findIndex(item => item._id === id);
        if (index !== -1) {
            updatedShowTodo[index].completed = !updatedShowTodo[index].completed;
            axios.post(
                BACKEND_API_URL + '/api/auth/user/todo/update',
                { todoId: updatedShowTodo[index]._id, completed: updatedShowTodo[index].completed },
                { withCredentials: true }
            )
                .then(response => {
                    setShowTodo(response.data.data.list);
                    setUncheckedCurrentItems(response.data.data.list.filter(item => !item.completed));
                    setCurrentAllItems(response.data.data.list);
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            console.error(`Todo with _id ${id} not found.`);
        }
    };

    const calculateProgress = () => {
        if (showTodo.length === 0) {
            return 0;
        }

        const completedCount = showTodo.filter(item => item.completed).length;
        const per = (completedCount / showTodo.length) * 100;
        return per.toFixed(0)
    };





    return (
        <div className='dashboard-section'>
            <div className='flex justify-between items'>
                <h3>Tasks</h3>
                <DropdownButton label={<BsThreeDotsVertical size={20} />} type={'singleLeftItems'} options={[
                    { label: `${showAll ? 'Show Unchecked' : 'Show All'}`, onClick: () => toggleShowAll(), icon: <FaCheck className="text-blue-600 font-bold" /> },
                ]} />
            </div>

            {/* Progress bar */}
            <div className="mt-3 flex items-center">
                <div className="flex-grow h-2 bg-teal-200 rounded-full overflow-hidden">
                    <div
                        style={{ width: `${calculateProgress()}%` }}
                        className={`h-full bg-teal-500 transition-all duration-500 ease-in-out`}
                    ></div>
                </div>
                <div className="text-xs ml-2 font-semibold text-teal-600">
                    {calculateProgress()}%
                </div>
            </div>

            {uncheckedCurrentItems.length === 0 && currentAllItems.length === 0 && (
                <p className="italic text-sm text-center py-3">Create your first task</p>
            )}

            {showAll ? (
                <>
                    {/* Display all todos */}
                    <ul className="mt-2 space-y-1">
                        <div className="max-h-[150px] overflow-auto">
                            {currentAllItems.map((item, index) => (
                                <li key={index} className={`flex items-center group hover:bg-gray-100 px-2 rounded-md`}>
                                    <label className="flex-grow flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={item.completed}
                                            onChange={() => handleTodoToggle(item._id, item.completed)}
                                            className="mr-3 custom-checkboxes"
                                        />
                                        <span className={`text-sm ${item.completed ? 'line-through' : ''} ml-1`}>{item.todo}</span>
                                    </label>
                                    <span onClick={() => handleDeleteTodo(item._id)} className="ml-3 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer">
                                        <FaTrash className="text-red-500 opacity-50" />
                                    </span>
                                </li>
                            ))}
                        </div>
                        {/* Pagination for all items */}
                        <div className="flex justify-between">
                            <TextArea getTodos={getTodos} />
                        </div>
                    </ul>
                </>
            ) : (
                <>
                    {/* Display unchecked todos */}
                    <ul className="mt-2 space-y-1">
                        <div className="max-h-[150px] overflow-auto">
                            {uncheckedCurrentItems.map((item, index) => (
                                <li key={index} className={`flex items-center group hover:bg-gray-100 px-2 rounded-md`}>
                                    <label className="flex-grow flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={item.completed}
                                            onChange={() => handleTodoToggle(item._id, item.completed)}
                                            className="mr-3 custom-checkboxes"
                                        />
                                        <span className={`text-sm ${item.completed ? 'line-through' : ''} ml-1`}>{item.todo}</span>
                                    </label>
                                    <span onClick={() => handleDeleteTodo(item._id)} className="ml-3 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer">
                                        <FaTrash className="text-red-500 opacity-50" />
                                    </span>
                                </li>
                            ))}
                            {uncheckedCurrentItems.length === 0 && (
                                <p className="italic text-sm text-center py-3">Work done, no more tasks to complete...</p>
                            )}
                        </div>
                        <div className="flex justify-between">
                            <TextArea getTodos={getTodos} />
                        </div>
                    </ul>
                </>
            )}

        </div>
    );
};

const TextArea = ({ getTodos }) => {
    const [todo, setTodo] = useState('');

    const handleChange = (e) => {
        setTodo(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(BACKEND_API_URL + '/api/auth/user/todo', { todo: todo }, { withCredentials: true })
            .then(response => {
                setTodo('')
                getTodos();
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <form className="flex space-x-2 w-full mt-3" onSubmit={handleSubmit}>
            <input
                type="text"
                name="name"
                id="name"
                value={todo}
                onChange={handleChange}
                placeholder='Create a todo'
                required
                className="pl-2 w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <button
                type="submit"
                className="bg-indigo-600 hover:bg-indigo-500 text-white px-4 py-2 rounded-md shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                +
            </button>
        </form>
    )
}

export default Todo;
