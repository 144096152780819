import React from 'react';

const Pagination = ({ currentPage, maxPage, onPageChange }) => {
  const handleChange = (event) => {
    const page = Number(event.target.value);
    if (page >= 1 && page <= maxPage) {
      onPageChange(page);
    }
  };

  return (
    <div className="inline-flex items-center justify-center rounded py-1 text-black cursor-pointer">
      <div
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`inline-flex size-8 items-center justify-center rtl:rotate-180 ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-500'
          }`}
      >
        <span className="sr-only">Prev Page</span>
        {'<'}
      </div>

      <span className="h-4 w-px bg-white/25" aria-hidden="true"></span>

      <div>
        <label htmlFor="PaginationPage" className="sr-only">
          Page
        </label>

        <input
          type="number"
          className="h-8 w-10 rounded border-none bg-transparent p-0 text-center text-xs font-medium [-moz-appearance:_textfield] focus:outline-none focus:ring-inset focus:ring-white [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
          min="1"
          max={maxPage}
          value={currentPage}
          onChange={handleChange}
          id="PaginationPage"
        />
      </div>

      <span className="h-4 w-px bg-white/25"></span>

      <div
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === maxPage}
        className={`inline-flex size-8 items-center justify-center rtl:rotate-180 ${currentPage === maxPage ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-500'
          }`}
      >
        <span className="sr-only">Next Page</span>
        {'>'}
      </div>
    </div>
  );
};

export default Pagination;
