import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthForm.css'; // Import the CSS file for styling
import { IoIosClose } from 'react-icons/io';
import axios from 'axios';
import SendEmail from '../Tools/SendEmail'
import Welcome from '../../emails/Welcome.tsx';
import { render } from '@react-email/components';
import CustomInput from '../Tools/Input/CustomInput.js';
import { FiUser } from 'react-icons/fi';
import { MdDashboardCustomize, MdOutlineMailOutline, MdOutlineManageAccounts } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';
import { FaCalendarAlt, FaShareAlt } from "react-icons/fa";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const AuthForm = ({ isLogin, checkLoginStatus, sendOpenModal }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const { name, email, password } = formData;

  //password strength
  const [num, setNum] = useState(false)
  const [passLength, setPassLength] = useState(false)

  useEffect(() => {
    // Check for number
    if (password.match(/([0-9])/) && password.match(/([0-9])/)) {
      setNum(true);
    } else {
      setNum(false);
    }

    // Check for password length
    if (password.length >= 6) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password])

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAuthentication = async (formData, isLogin) => {
    try {
      const response = isLogin
        ? await axios.post(BACKEND_API_URL + '/api/auth/login', formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        : await axios.post(BACKEND_API_URL + '/api/auth/register', formData, {
          withCredentials: true,
        });

      if (response.data.error) {
        setError(response.data.error);
      } else {
        checkLoginStatus();
        if (isLogin === false) {
          const emailHtml = render(<Welcome username={formData.name} link={response.data.link} />)
          await SendEmail('Welcome to SocialFlick – Your Journey Begins Here 🚀', emailHtml);
        }

        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
      }
    } catch (error) {
      if (error.response && error.response.data.error) {
        setError(error.response.data.error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isLogin) {
      if (!email || !password) {
        console.log('Missing user data from the form!');
        return;
      }
    } else {
      if (!email || !password) {
        console.log('Missing user data from the form!');
        return;
      }

      if (!num || !passLength) {
        console.log('Select a stronger password!');
        return;
      }
    }

    handleAuthentication(formData, isLogin);
  };

  const setIsModalOpen = (email) => {
    sendOpenModal(email);
  }
  return (
    <div className="--theme-colors flex flex-col md:flex-row justify-between gap-5 items-center --social-cards-shadow rounded-xl">
      {!isLogin && (
        <FeatureList />
      )}

      <div className='flex-col p-5'>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {isLogin && (
            <img
              className="mx-auto h-[100px] w-auto"
              src="../logo/favicon-black.png"
              alt="logo"
            />
          )}
          <h2 className="py-5 text-center text-2xl font-semibold leading-9 tracking-tight tracking-wide">
            {isLogin ? 'Log in to your account' : 'Create an account'}
          </h2>
        </div>

        <div className="px-5 min-w-[350px]">
          <form className="space-y-4" onSubmit={handleSubmit}>
            {!isLogin && (
              <div>
                <label htmlFor="email" className="block text-sm font-medium">
                  Username *
                </label>
                <CustomInput
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  placeholder="Type your username"
                  Icon={FiUser}
                />
              </div>
            )}
            <div>
              <label htmlFor="email" className="block text-sm font-medium">
                Email *
              </label>
              <div className="mt-2">
                <CustomInput
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Type your email"
                  Icon={MdOutlineMailOutline}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6">
                  Password *
                </label>

              </div>
              <CustomInput
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                placeholder="Type your password"
                Icon={RiLockPasswordLine}
              />
            </div>
            {isLogin && (
              <p className="text-sm text-right font-light text-indigo-600 hover:text-indigo-500 cursor-pointer" onClick={(e) => setIsModalOpen(email)}>
                Forgot password?
              </p>
            )}


            <div className="pt-5">
              <button
                type="submit"
                className="relative flex w-full justify-center rounded-xl bg-gradient-to-r from-teal-400 to-fuchsia-600 px-3 py-2 text-sm font-semibold leading-6 shadow-sm hover:from-teal-500 hover:via-fuchsia-600 hover:to-teal-500 transition-all duration-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {isLogin ? 'Log in' : 'Sign up →'}
              </button>
            </div>
          </form>
          {!isLogin && (
            <div className='text-[10px] text-left italic mx-auto'>
              <p>By signing up, you agree to our <a href='https://socialflick.io/terms/' target='_blank' rel="noreferrer" className='underline'>Terms of Service</a> and <a href='https://socialflick.io/privacy-policy/' className='underline' target='_blank' rel="noreferrer">Privacy Policy</a>.</p>
            </div>
          )}
          <div>
            {!isLogin && password.length > 0 && !num && (
              <div className='passwordStrength mt-2'>
                <IoIosClose color='red' fontSize={30} /> Has to include a number
              </div>
            )}
            {!isLogin && password.length > 0 && !passLength && (
              <div className='passwordStrength'>
                <IoIosClose color='red' fontSize={30} /> At least 6 chars
              </div>
            )}
          </div>
          {error &&
            <div >
              <p className='--error-message'>{error}</p>
            </div>
          }

          {isLogin ? (
            <p className="mt-6 text-center text-sm text-gray-500">
              New to SocialFlick?{' '}
              <a href="/signup" rel="noreferrer" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Sign up for free
              </a>
            </p>
          ) : (
            <p className="mt-6 text-center text-sm text-gray-500">
              Already have an account?{' '}
              <a href="/login" rel="noreferrer" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Log in
              </a>
            </p>
          )}
        </div>
      </div>

    </div>
  );
};

const FeatureList = () => {
  return (
    <div className="relative bg-cover bg-center py-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/random/featurelist.jpg)` }}>
      {/* Grayish overlay */}
      <div className="absolute inset-0 bg-gray-900 opacity-80"></div>

      <div className="relative z-10 flex flex-col">
        <div className='flex flex-col justify-center items-center mb-3 !px-5'>
          <img src={`${process.env.PUBLIC_URL}/logo/favicon-nobg.png`} alt='logo' className='w-[100px]' />
          <h2 className='text-4xl font-bold text-white text-center drop-shadow-lg'>
            Welcome to SocialFlick
          </h2>
          <p className='italic py-1 text-center text-gray-200 drop-shadow-lg'>
            Embark on a smoother journey in managing your social media career with us!
          </p>
        </div>
      </div>

      <ul className="relative z-10 list-none w-[80%] mx-auto">
        <li className="flex items-start py-4 border-b border-gray-600">
          <div className="p-3">
            <FaShareAlt size={40} className="text-blue-500" />
          </div>
          <div>
            <p className="font-bold text-white drop-shadow-lg">Upload Once, Share Everywhere</p>
            <p className="text-sm text-gray-200 drop-shadow-md">
              Easily post your content on all your favorite social media platforms with just one upload - one click.
            </p>
          </div>
        </li>
        <li className="flex items-start py-4 border-b border-gray-600">
          <div className="p-3">
            <FaCalendarAlt size={40} className="text-blue-500" />
          </div>
          <div>
            <p className="font-bold text-white drop-shadow-lg">Stay Organized with an Easy-to-Use Calendar</p>
            <p className="text-sm text-gray-200 drop-shadow-md">
              Keep track of your content schedule and never miss a post with our simple calendar feature.
            </p>
          </div>
        </li>
        <li className="flex items-start py-4 border-b border-gray-600">
          <div className="p-3">
            <MdOutlineManageAccounts size={40} className="text-blue-500" />
          </div>
          <div>
            <p className="font-bold text-white drop-shadow-lg">Easy management</p>
            <p className="text-sm text-gray-200 drop-shadow-md">
              Save your ideas as a draft and create templates for your upcoming posts, making content creation a breeze.
            </p>
          </div>
        </li>
        <li className="flex items-start py-4">
          <div className="p-3">
            <MdDashboardCustomize size={40} className="text-blue-500" />
          </div>
          <div>
            <p className="font-bold text-white drop-shadow-lg">Customize Your Content with Templates</p>
            <p className="text-sm text-gray-200 drop-shadow-md">
              Design and manage your own caption templates for your posts, ensuring your content and brand stands out.
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}


export default AuthForm;