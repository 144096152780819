import React, { useState, useEffect, useRef } from 'react';
import './Modal.css';
import 'react-phone-number-input/style.css'; // Import CSS styles for react-phone-number-input
import axios from 'axios'; // Import Axios
import ResetPassword from '../Auth/ResetPassword/ResetPassword';
import { FaTimes } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import { addMonths } from 'date-fns';

//buttons
import TikTokLoginButton from '../Buttons/TikTok/TikTokLoginButton';
import InstagramLoginButton from '../Buttons/Facebook/InstagramLoginButton';
import FacebookLoginButton from '../Buttons/Facebook/FacebookLoginButton';
import YoutubeLoginButton from '../Buttons/Google/GoogleLoginButton';
import PasswordInput from '../Tools/Input/CustomInput.js';
import { useNavigate } from 'react-router-dom';
import { render } from '@react-email/components';
import EmailVerify from '../../emails/EmailVerify.tsx';
import DeleteAccountAdmin from '../../emails/DeleteAccountAdmin.tsx';
import DeleteAccountUser from '../../emails/DeleteAccountUser.tsx';
import Upload from '../../pages/Protected/Content/Upload/Upload.js';

import SendEmail from '../Tools/SendEmail.js';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const Modal = ({ open, closeModal, type, otherInfo }) => {

  // Close modal on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const modalOverlay = document.querySelector('.modal-overlay');
      if (open && event.target === modalOverlay && type !== 'ScheduledModal') {
        closeModal();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [open, closeModal, type]);

  // Close modal on Escape key press
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape' && open) {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [open, closeModal, type]);


  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 modal-overlay ${open ? '' : 'hidden'}`}>
      <div className={`bg-white rounded-lg overflow-hidden shadow-xl transform transition-all !w-min`}>

        {type !== 'ScheduledModal' && (
          <div className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={closeModal}>
            <FaTimes size={16} />
          </div>
        )}
        {type === 'PasswordModal' && <PasswordModal closeModal={closeModal} email={otherInfo} />}
        {type === 'VerifyEmailModal' && <VerifyEmailModal closeModal={closeModal} name={otherInfo} />}
        {type === 'TemplatesModal' && <TemplatesModal closeModal={closeModal} type={otherInfo} />}
        {type === 'ConnectSocialsModal' && <ConnectSocialsModal closeModal={closeModal} />}
        {type === 'VideoUploaderModal' && <VideoUploaderModal closeModal={closeModal} draft={otherInfo} />}
        {type === 'ScheduledModal' && <ScheduledModal date={otherInfo} closeModal={closeModal} />}
        {type === 'VerifyDelete' && <VerifyDelete closeModal={closeModal} draft={otherInfo} />}
        {type === 'ConfirmHideGuide' && <ConfirmHideGuide closeModal={closeModal} />}
        {type === 'AddTeamMemberModal' && <AddTeamMemberModal closeModal={closeModal} userInfo={otherInfo} />}
        {type === 'DeleteAccountModal' && <DeleteAccountModal closeModal={closeModal} userInfo={otherInfo} />}

      </div>
    </div>
  );
}
const PasswordModal = ({ closeModal, email }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
  const [emailInput, setEmailInput] = useState(email ? email : '');
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true); // Add isValidEmail state

  const openResetPassword = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${BACKEND_API_URL}/api/auth/changepassword/getverificationcode`, { email: emailInput }, { withCredentials: true });
      if (response.status === 200) {
        setIsResetPasswordOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    // Validate emailInput and update isValidEmail state
    const isValid = validateEmail(emailInput);

    // Update isConfirmButtonDisabled based on dependencies
    setIsConfirmButtonDisabled(!isValid || isLoading);
  }, [emailInput, isLoading]);


  return (
    <>
      <div className="bg-white px-4 py-5 sm:p-6 modal-wrapper items-center justify-center flex-column w-[500px]">
        {isResetPasswordOpen ? (
          <>
            <ResetPassword firstStep={false} />
          </>
        ) : (
          <>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4 text-center">
              RESET YOUR PASSWORD
            </h2>
            <p className="text-gray-700 mb-4">
              To enhance your account security, we will send you an email containing a verification code to reset your password.
            </p>
            <div className='text-center'>
              <input
                type="email"
                value={emailInput}
                onChange={(e) => { setEmailInput(e.target.value); }}
                placeholder="Enter your email"
                className="bg-gray-100 rounded-full py-2 px-4 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400 mr-5"
              />
              {isLoading ? (
                <div className="loading-animation"></div>
              ) : (
                <button
                  onClick={openResetPassword}
                  className={`bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 ${isConfirmButtonDisabled ? 'bg-gray-300 cursor-not-allowed' : ''}`}
                  disabled={isConfirmButtonDisabled}
                >
                  Confirm
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

const VerifyEmailModal = ({ closeModal, name }) => {
  const [responseData, setResponsData] = useState(null);
  const [error, setError] = useState(false);


  const sendVerifyEmail = async () => {
    try {
      const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/verification`, { withCredentials: true });
      if (response.status === 200) {
        console.log(response);
        const emailHtml = render(<EmailVerify username={name} link={response.data.link} />)
        const subject = 'Verify your SocialFlick account!'
        await SendEmail(subject, emailHtml);
      }
    } catch (error) {
      setResponsData(error.response.data.error);
      setError(true);
    }

  }
  return (
    <>
      <div className="bg-white px-4 py-5 sm:p-6 modal-wrapper items-center justify-center flex-column text-center w-[500px]">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">
          Email Verification
        </h2>
        <p className="text-gray-700 mb-4">
          Thank you for signing up! To complete your registration, please click the button below to verify your email address.
        </p>
        <p className="text-gray-700 mb-6 italic">
          Note: This link will expire in 30 minutes.
        </p>
        {responseData ? (
          <button onClick={closeModal} className="bg-red-400 hover:bg-blue-400 text-white font-semibold py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
            Close
          </button>
        ) : (
          <button onClick={sendVerifyEmail} className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
            Confirm
          </button>
        )}
        {responseData && <p className={error ? '--error-message' : '--success-message'}>{responseData}</p>}

      </div>
    </>
  )
}

const TemplatesModal = ({ closeModal, type }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const hasRunOnceRef = useRef(false)
  const [count, setCount] = useState(0);


  useEffect(() => {
    if (!hasRunOnceRef.current) {
      hasRunOnceRef.current = true;
      if (type.type === 'edit_hashtag' || type.type === 'edit_caption') {
        setTitle(type.item.name);
        setDescription(type.item.info);
        if (type.type === 'edit_hashtag') {
          const hashtags = type.item.info.match(/#\w+/g);
          const count = hashtags ? hashtags.length : 0;
          setCount(count);
        }
      }
    }
  }, [])



  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);

    const hashtags = inputValue.match(/#\w+/g);
    const count = hashtags ? hashtags.length : 0;
    setCount(count);
  };

  const handleSubmit = async () => {
    const data = {};
    data.name = title;
    data.info = description; //has to be inserted as array
    data.type = type;

    try {
      if (type.type === 'edit_hashtag' || type.type === 'edit_caption') {
        await axios.post(`${BACKEND_API_URL}/api/auth/user/templates/hashtag/update`, data, { withCredentials: true });
        closeModal();
      } else {
        await axios.post(`${BACKEND_API_URL}/api/auth/user/templates/hashtag`, data, { withCredentials: true });
        closeModal();
      }

    } catch (error) {
      console.log(error);
    }

  };
  return (
    <div className="bg-white px-4 py-5 sm:p-6 flex-column w-[500px] max-w-full">
      <h2 className="text-2xl font-semibold text-gray-900 mb-4 text-center">
        {type === 'hashtag' ? 'Hashtag Groups' : 'Caption Templates'}
      </h2>
      <form className="mb-4">
        <div className="mb-4">
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            placeholder={type === 'hashtag' ? 'Name the hashtag group' : 'Name your caption template'}
            value={title}
            onChange={handleTitleChange}
            required
          />
        </div>

        <div className="mb-4">
          <textarea
            className="w-full h-24 px-3 py-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:border-indigo-500"
            placeholder={type === 'hashtag' ? 'Enter your desired hashtags, as you would like them to apear in the caption. Without commas.' : 'Format your caption template to your desire...'}
            value={description}
            onChange={handleDescriptionChange}
            required
          ></textarea>
        </div>

        <div className={`${type === 'hashtag' || type.type === 'edit_hashtag' ? 'flex' : 'text-right'}`}>
          {type === 'hashtag' || type.type === 'edit_hashtag' ? (
            <div className='flex-1 text-[15px] text-black self-end'>
              Hashtag count: <span className='font-semibold'>{count}</span>
            </div>
          ) : ''}
          <button
            type="button"
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 mr-3"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 mr-2"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};




const ConnectSocialsModal = ({ closeModal }) => {
  const socials = [
    { platform: 'tiktok', info: 'TikTok description', component: TikTokLoginButton },
    { platform: 'youtube', info: 'YouTube description', component: YoutubeLoginButton },
    { platform: 'instagram', info: 'Instagram description', component: InstagramLoginButton },
    { platform: 'facebook', info: 'facebook description', component: FacebookLoginButton },
    // Add more social media platforms as needed
  ];

  const handleSocialClick = () => {
    closeModal();
  };
  return (
    <>
      <div className="bg-white px-4 py-5 sm:p-6 modal-wrapper items-center justify-center flex-column text-center w-[500px] max-w-full">
        <h2 className="text-2xl font-semibold text-gray-900">
          Connect to socials
        </h2>
        <p className='text-sm mb-5'>Add your social media you wish to post to.</p>
        {socials.map((social, index) => (
          <div key={index} className='mb-3' onClick={handleSocialClick}>
            {social.component && <social.component />}
          </div>

        ))}

      </div>
    </>
  );
};


const VideoUploaderModal = ({ draft }) => {
  return (
    <div className='min-w-[900px] max-w-[100%] p-5'>
      <Upload isModal={true} draftSchedule={draft.draft} />
    </div>
  );
};



const ScheduledModal = ({ date, closeModal }) => {
  const [startDate, setStartDate] = useState(date || new Date());

  useEffect(() => {
    const today = new Date();
    console.log(date);

    if (date < today) {
      setStartDate(today);
    }
  }, [date]);

  return (
    <div className="bg-white text-center w-[500px]">
      <div className='flex flex-col'>
        <h2 className="text-2xl font-semibold !text-gray-200 py-2 mb-4 text-center --dark-theme-color">
          Schedule Video
        </h2>
        <p className='w-3/4 mx-auto mb-5'>Select time and date...</p>
      </div>

      <div className='flex flex-col w-max mx-auto'>
        <div>
          <DatePicker
            selected={startDate}
            placeholderText="Schedule"
            onChange={(date) => setStartDate(date)}
            minDate={new Date()} // min now
            maxDate={addMonths(new Date(), 3)} // 3 months max
            shouldCloseOnSelect={false}
            showTimeInput
            timeInputLabel="Time:"
            showIcon={true}
            inline={true}
          />
          <div>
            {startDate?.toLocaleString(undefined, { hour12: false, hour: 'numeric', minute: 'numeric', month: 'numeric', day: 'numeric' })}
          </div>
        </div>

        <div className='flex w-full  gap-2 my-5 text-white text-sm justify-between'>
          <div className={`bg-blue-500 px-3 py-2 rounded-md cursor-pointer ${startDate === null && '!bg-gray-500 !cursor-not-allowed'}`} onClick={startDate !== null ? (e) => closeModal(startDate) : null}>
            Publish
          </div>
          <div className='bg-red-500 px-3 py-2 rounded-md cursor-pointer' onClick={(e) => closeModal('cancel')}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  )
}

const VerifyDelete = ({ closeModal, draft }) => {
  const [isCheckedArray, setIsCheckedArray] = useState(new Array(draft.posts.length).fill(true));

  // Function to handle checkbox toggle
  const handleCheckboxChange = (index) => {
    const updatedCheckedArray = [...isCheckedArray];
    updatedCheckedArray[index] = !updatedCheckedArray[index];
    setIsCheckedArray(updatedCheckedArray);
  };

  const handleOneDelete = async (id) => {
    try {
      await axios.delete(BACKEND_API_URL + `/api/auth/user/drafts/${id}`, {
        withCredentials: true,
      });
      return;
    } catch (error) {
      console.error('Error deleting draft:', error);
    }
  };

  const confirmDelete = async () => {
    try {
      const checkedItemIds = [];
      isCheckedArray.forEach((isChecked, index) => {
        if (isChecked) {
          // If the checkbox is checked, add the corresponding ID to the array
          checkedItemIds.push(draft.posts[index]._id);
        }
      });

      // Check if there are any checked items
      if (checkedItemIds.length > 0) {
        for (const id of checkedItemIds) {
          await handleOneDelete(id);
        }
        // Close the modal or perform any other necessary actions
        closeModal();
      } else {
        console.log('No checkboxes are checked.');
      }
    } catch (error) {
      console.error('Error deleting drafts:', error);
    }
  };

  return (
    <div className="modal-wrapper bg-white p-6 rounded-lg text-center shadow-lg flex flex-col w-[500px]">
      <div className='text-lg font-semibold mb-5'>
        Confirm to delete your posts
      </div>
      {draft.posts.map((post, index) => (
        <div key={post._id} className={`flex  shadow-sm gap-2 mb-5 justify-between items-center border border-gray-300 ${post.status === 'failed' && 'text-red-400'}`}>
          <div className='ml-2'>
            <input
              type="checkbox"
              className='checkboxDelete p-4 focus:outline-none focus:ring-opacity-0 focus:ring-0'
              checked={isCheckedArray[index]}
              onChange={() => handleCheckboxChange(index)} // Toggle checkbox state
              style={{ appearance: 'none' }}
            />
          </div>

          <div className={`px-2 text-gray-700 uppercase `}>
            <div className="max-w-max text-center">
              {post.status} <br />
              <p className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">{post.uploadTo}</p>
              {post.status === 'scheduled' && (
                <p className="text-[10px]">{new Date(post.schedule).toLocaleString()}</p>
              )}
            </div>

          </div>
          <div className="text-gray-900 w-3/5 flex flex-col">
            <div className='truncate font-medium'>{post.title}</div>
            <div className='multi-line-ellipsis text-[10px]'>{post.description}</div>
          </div>
          <div className="text-gray-700 p-1 w-[80px] h-[80px]">
            <img
              src={post.thumbnail || post.firebase_path || `${process.env.PUBLIC_URL}/no-thumbnail.png`}
              alt={post.title}
              className="w-full h-full object-cover shadow-sm"  // Adds a light shadow
            />
          </div>
        </div>
      ))}

      <div className="flex justify-center">
        <button
          onClick={(e) => confirmDelete()}
          disabled={!isCheckedArray.some((isChecked) => isChecked)}
          className={`group bg-blue-500 hover:bg-blue-600 rounded-md text-white font-semibold py-2 px-4 mr-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 ${!isCheckedArray.some((isChecked) => isChecked) && 'bg-gray-500 hover:bg-gray-500'}`}
          title={!isCheckedArray.some((isChecked) => isChecked) ? "Select at least one to delete, or cancel." : ""}
        >
          Confirm
        </button>

        <button
          onClick={closeModal}
          className="bg-red-400 hover:bg-red-500 text-white rounded-md font-semibold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const ConfirmHideGuide = ({ closeModal }) => {


  const confirmDelete = async () => {
    try {
      await axios.post(BACKEND_API_URL + '/api/auth/user/settings', { showGuide: false }, {
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    }
    closeModal(true);
  };

  const cancelDelete = () => {
    closeModal(false);
  };

  return (
    <div className="modal-wrapper bg-white p-6 rounded-lg text-center shadow-lg flex flex-col">
      <div className='text-lg font-semibold mb-5'>
        Are you sure you want to hide this guide?
      </div>

      <div className="flex justify-center">
        <button
          onClick={confirmDelete}
          className={`group bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 mr-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50`}
        >
          Confirm
        </button>

        <button
          onClick={cancelDelete}
          className="bg-red-400 hover:bg-red-500 text-white font-semibold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};


const AddTeamMemberModal = ({ closeModal, userInfo }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: 'team_admin',
    team_id: userInfo._id || ''
  });
  const [errorResponse, setErrorResponse] = useState('')

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send form data to the API
      const response = await axios.post(BACKEND_API_URL + '/api/auth/register/team', formData, {
        withCredentials: true,
      });
      if (response.status === 201) {
        closeModal();
      }
    } catch (error) {
      if (error.response.data.error) {
        setErrorResponse(error.response.data.error)
      }
    }
  }


  return (
    <div className="modal-wrapper bg-white p-6 rounded-lg text-center shadow-lg flex flex-col">
      <div className='text-lg font-semibold mb-5'>
        Add a Team Member
      </div>


      <form className='flex flex-col text-start' onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Username"
          value={formData.username}
          onChange={handleChange}
          autoComplete='off'
          className="border border-gray-300 px-3 py-2 rounded-md mb-4"
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          autoComplete='off'
          value={formData.email}
          onChange={handleChange}
          className="border border-gray-300 px-3 py-2 rounded-md mb-4"
          required
        />
        <div>
          <PasswordInput name={'password'} value={formData.password} onChange={handleChange} />

        </div>
        <p className='text-[10px] mb-4 italic'>* Team member can change the password after login.</p>

        {errorResponse && (
          <p className='text-center text-red-500 text-sm'>{errorResponse}</p>
        )}
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">Add Team Member</button>
      </form>

    </div>
  );
};

const DeleteAccountModal = ({ closeModal, userInfo }) => {
  const navigate = useNavigate();
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [freeInput, setFreeInput] = useState('');

  const reasons = [
    "I don't find it useful",
    "I am switching to a different service",
    "I am experiencing technical issues",
    "Too difficult to use",
    "Other"
  ];

  const handleCheckboxChange = (reason) => {
    setSelectedReasons((prev) =>
      prev.includes(reason)
        ? prev.filter((r) => r !== reason)
        : [...prev, reason]
    );
  };

  const confirmDelete = async () => {
    const admin = render(<DeleteAccountAdmin userInfo={userInfo} reason={selectedReasons} input={freeInput} />)
    const user = render(<DeleteAccountUser userInfo={userInfo} reason={selectedReasons} />)

    try {
      await SendEmail('User has deleted his account', admin, 'contact@socialflick.io');
      await SendEmail('You have deleted your account at SocialFlick', user);
      await axios.get(BACKEND_API_URL + '/api/auth/user/delete', {
        withCredentials: true,
      });

      navigate(0);
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const cancelDelete = () => {
    closeModal();
  };

  const handleFreeInputChange = (event) => {
    setFreeInput(event.target.value);
  };

  return (
    <div className="modal-wrapper bg-white p-6 rounded-lg text-center shadow-lg flex flex-col gap-3 w-[500px]">
      <p className='font-bold text-red-500'>
        DELETE ACCOUNT
      </p>

      <div className="mx-auto w-max">
        <p className="font-semibold mb-2">What is your biggest factor for leaving SocialFlick?</p>
        <div className="checkbox">
          {reasons.map((reason) => (
            <div key={reason} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={reason}
                checked={selectedReasons.includes(reason)}
                onChange={() => handleCheckboxChange(reason)}
                className="mr-2"
              />
              <label htmlFor={reason} className="text-sm">{reason}</label>
            </div>
          ))}
        </div>
      </div>

      <div className="mx-auto w-full mt-2">
        <textarea
          value={freeInput}
          onChange={handleFreeInputChange}
          className="border rounded-lg p-2 w-full"
          rows="3"
          placeholder="Please provide any additional comments or reasons for leaving to help us improve."
        />
      </div>

      <p className='text-[10px] italic'>
        * After 30 days, your account at SocialFlick will be removed entirely from our system, including videos, templates, history uploads, statistics and personal settings.
      </p>

      <div className="flex justify-center gap-5 items-end">
        <div
          onClick={confirmDelete}
          className={`bg-red-400 hover:bg-red-500 text-white font-semibold py-1 px-2 text-sm cursor-pointer`}
        >
          Delete
        </div>

        <div
          onClick={cancelDelete}
          className="border border-gray-400 hover:bg-gray-100 text-black font-semibold py-1 px-2 text-sm cursor-pointer"
        >
          Cancel
        </div>
      </div>
    </div>
  );
};


export default Modal;
