import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Todo from '../../../components/ToDo/Todo';
import Socials from '../Socials/Socials';
import Drafts from '../../../components/Drafts/Drafts';
import { FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Onboarding from '../../../components/Onboarding/Onboarding';
import Guide from '../../../components/Onboarding/Guide';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';
import useLocalStorage from 'use-local-storage';
import { isMobile as checkIsMobile, handleResize, debounce } from '../../../utils/utils'; // Import utility functions
import Heatmap from '../../../components/Statistics/Heatmap';
import BarChart from '../../../components/Statistics/Bar';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

// Assets
const Dashboard = ({ checkLoginStatus }) => {
    const [allUploads, setAllUploads] = useState({ uploads: [], percChange: 0 });
    const hasRunOnceRef = useRef(false);
    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const [userSettings, setUserSettings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [username] = useLocalStorage("username");
    const [isMobile, setIsMobile] = useState(checkIsMobile());
    const navigate = useNavigate();  // Use useHistory

    useEffect(() => {
        const debouncedHandleResize = debounce(() => handleResize(setIsMobile), 100);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, [isMobile]);

    useEffect(() => {
        if (!hasRunOnceRef.current) {
            hasRunOnceRef.current = true;
            checkLoginStatus();

            (async () => {
                const user = await getUser();
                const onboardingBool = await userHasCompletedOnboarding(user);

                if (onboardingBool === false) {
                    setShowOnboardingModal(true);
                }
                setIsLoading(false);
            })();

            // GET ALL DRAFTS
            (async () => {
                try {
                    const response = await axios.get(BACKEND_API_URL + '/api/auth/user/drafts', {
                        withCredentials: true, // Include this option if you need cookies
                    });
                    setAllUploads(response.data)
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [checkLoginStatus])

    const getUser = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/isLoggedIn', { withCredentials: true });
            return response.data.user;
        } catch (error) {
            console.error("Error checking user login status:", error);
            throw error; // Optionally re-throw the error for handling in the calling code
        }
    };

    const userHasCompletedOnboarding = async (user) => {
        const response = await axios.get(BACKEND_API_URL + '/api/auth/user/settings', { withCredentials: true });
        // IF there is no user settings OR there is usersetting with onboarding not completed, show the modal
        setUserSettings(response.data);

        //if user has hided the onboarding Guide dont show the modal
        if (response.data.showGuide === false) return true;

        //show the modal if its not completed
        if (response.data === undefined || response.data.onboarding.completed === false) return false;

        // Else the onboarding is completed, ie dont show the modal
        return true;
    };

    const onClose = () => {
        setShowOnboardingModal(false);
    }

    const handleEditDraft = (upload) => {
        navigate(`/dashboard/upload`, { state: { upload } });
    }

    return (
        <div className={`dashboard-container flex flex-col gap-3 ${isMobile ? '!w-full' : '!w-[95%]'}`}>
            {isLoading === false ? (
                <>
                    <div className='heading flex justify-between'>
                        <h1>Welcome to your dashboard
                            {username && username !== '' && (
                                <span className=''>, {username}</span>
                            )} 👋
                        </h1>
                    </div>

                    <div className='flex flex-col md:flex-row gap-5'>
                        <div className='dashboard-section w-full md:w-1/3'>
                            <BarChart data={userSettings.logs} />
                        </div>
                        <div className={`${isMobile && ''} dashboard-section w-full md:w-2/3`}>
                            <Heatmap data={allUploads || []} showWeekly={true} />
                        </div>
                    </div>

                    {/* GUIDE FOR NEW USERS */}
                    {userSettings && userSettings.showGuide === true && (
                        <Guide userSettings={userSettings} />
                    )}

                    <div className={`flex gap-3 ${isMobile ? 'flex-col' : 'justify-between'}`}>
                        <div className={`dashboard-section !p-0 !m-0 !h-[230px] flex flex-col items-center justify-center text-center bg-image-opacity relative ${isMobile ? 'w-full' : 'w-[20%]'}`}>
                            {/* Content inside the div */}
                            <h4 className='font-bold relative z-10'>Publish a post</h4>
                            <p className='text-sm p-3 relative z-10'>
                                Publish, schedule, or queue to engage your audience precisely when it matters most.
                            </p>
                            <div className={`shadow-lg shadow-blue-500/30 bg-blue-500 text-sm text-white p-3 hover:bg-blue-700 mx-auto font-semibold rounded-full cursor-pointer transition-all duration-200 flex items-center w-[max-content] justify-center relative z-10`}>
                                <Link to="/dashboard/upload" className="flex">
                                    Upload
                                    <FaPlus size={18} className="ml-2" />
                                </Link>
                            </div>
                        </div>

                        {/* Drafts section */}
                        <div className={`dashboard-section self-start ${isMobile ? 'w-full' : 'w-[58%]'}`}>
                            <Drafts onEditDraft={handleEditDraft} />
                        </div>

                        {userSettings && userSettings.showTodo === true && (
                            <div className={`${isMobile ? 'w-full' : 'w-[25%]'}`}>
                                <Todo />
                            </div>
                        )}
                    </div>

                    {/* ROW THREE SOCIALS */}
                    <div className='flex w-[100%]'>
                        <div className='flex-1'>
                            <Socials value={'dashboard'} />
                        </div>
                    </div>
                </>
            ) : (
                <div className='flex justify-center items-center w-full'>
                    <LoadingIndicator />
                </div>
            )}

            {showOnboardingModal && (
                <Onboarding onClose={onClose} />
            )
            }
        </div >
    );
}


export default Dashboard;