import React, { useCallback, useEffect, useRef, useState } from 'react';
import VideoUploader from '../../../../components/VideoUploader/VideoUploader';
import { MdImage, MdVideocam } from 'react-icons/md';
import DraftsBig from '../../../../components/Drafts/DraftsBig';
import { getFileType } from '../../../../utils/getFileType';
import { useLocation } from 'react-router-dom';

const Upload = ({ isModal = false, draftSchedule }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const hasRunOnceRef = useRef(false);
    const hasRunOnceRef2 = useRef(false);
    const location = useLocation();
    const [draft, setDraft] = useState(null);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    const handleEditDraft = useCallback(async (draft) => {
        setDraft(draft);
        // Set selectedOption based on the result
        if (getFileType(draft.posts[0].uploadType) === 'video') {
            setSelectedOption('video');
        } else {
            setSelectedOption('post');
        }
    }, []);

    useEffect(() => {
        if (!hasRunOnceRef2.current) {
            if (draftSchedule) {
                handleEditDraft(draftSchedule);
            }
            hasRunOnceRef2.current = true;
        }
    }, [draftSchedule, handleEditDraft]);

    useEffect(() => {
        if (!hasRunOnceRef.current) {
            if (location.state && location.state.upload) {
                console.log(location);

                handleEditDraft(location.state.upload);
            }
            hasRunOnceRef.current = true;
        }
    }, [location, handleEditDraft]);

    if (selectedOption) {
        return <VideoUploader uploadType={selectedOption} draft={draft} />;
    }

    return (
        <div className={`flex flex-col justify-center items-center ${isModal ? 'h-[650px]' : 'h-screen'}`}>
            {/* Descriptive Text */}
            <div className='flex-grow flex flex-col items-center justify-center'>
                <h2 className="text-2xl font-medium text-center py-7">
                    What content do you want to upload?
                </h2>

                {/* Buttons Container */}
                <div className="flex flex-col md:flex-row items-center justify-center gap-5">
                    <div
                        className="w-[300px] h-[200px] border-border hover:border-blue-200 font-semibold text-lg rounded-lg hover:shadow-md rounded-xl border-2 transition-all transition duration-200 flex flex-col items-center justify-center cursor-pointer"
                        onClick={() => handleOptionSelect('post')}
                    >
                        <MdImage className="mb-2" size={36} />
                        Post/Image
                    </div>
                    <span>or</span>
                    <div
                        className="w-[300px] h-[200px] font-semibold text-lg rounded-xl border-2 border-border hover:border-blue-200 hover:shadow-md transition duration-200 flex flex-col items-center justify-center cursor-pointer"
                        onClick={() => handleOptionSelect('video')}
                    >
                        <MdVideocam className="mb-2" size={36} />
                        Video
                    </div>
                </div>
            </div>

            {/* Drafts Component at the Bottom */}
            <div className="rounded-md !w-[95%] mx-auto my-5 z-10">
                <DraftsBig onEditDraft={handleEditDraft} itemsPerPage={4} />
            </div>
        </div>
    );
};
export default Upload;
