import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Facebook = () => {
    const hasRunOnceRef = useRef(false)
    console.log(123);

    useEffect(() => {
        if (!hasRunOnceRef.current) hasRunOnceRef.current = true;
        else return;
        const broadcastChannel = new BroadcastChannel('socialsChannel');

        // Get parameters from url
        const url = window.location.hash;

        const queryString = url.split('#')[1];
        const params = new URLSearchParams(queryString);
        const paramsObject = {};
        params.forEach((value, key) => {
            paramsObject[key] = value;
        });
        //Google Access Token
        if (paramsObject) {
            (async () => {
                await facebook_generate_access_token(paramsObject);
            })();

            async function facebook_generate_access_token(paramsObject) {
                axios.post(BACKEND_API_URL + '/api/facebook/generate_access_token', paramsObject, { withCredentials: true })
                    .then(res => {
                        broadcastChannel.postMessage('callbackLogin');
                        window.close();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }, []);


    return (
        <div className='flex justify-center items-center w-full'>
            <LoadingIndicator />
        </div>
    )
}

export default Facebook