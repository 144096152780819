const DisplayError = () => {


    return (
        <div className='w-[50%] mx-auto'>
            <img src={`${process.env.PUBLIC_URL}/random/no-files.png`} alt="No Files Uploaded" />
        </div>
    );
};

export default DisplayError;
