import moment from 'moment';
import 'moment-timezone';


// returns 26/6 etc with timezone ....
export const formatCustomDate = (dateString, timeZone = moment.tz.guess()) => {
    const date = moment(dateString).tz(timeZone);
    //const now = moment().tz(timeZone);

    return date.format('DD/MM');
};


export const formatCustomDateTime = (dateString, timeZone = moment.tz.guess()) => {
    // Parse the date string with the specified timezone
    const date = moment(dateString).tz(timeZone);

    // Format the date to "HH:mm DD/MM"
    return date.format('HH:mm DD/MM');
};