import {
  Body,
  Button,
  Container,
  Head,
  Hr,
  Html,
  Img,
  Preview,
  Section,
  Text,
} from "@react-email/components";
import * as React from "react";

interface WelcomeProps {
  username: string;
  link: string;
}

export const Welcome = ({
  username,
  link
}: WelcomeProps) => (
  <Html>
    <Head />
    <Preview>
      Welcome to SocialFlick – Your Social Media Publishing Partner!
    </Preview>
    <Body style={main}>
      <Container style={container}>
        <Img
          src={`https://app.socialflick.io/logo/logo-vertical-black.png`}
          width={"50%"}
          alt="SocialFlick"
          style={logo}
        />
        <Text style={paragraph}>Hello {username},</Text>
        <Text style={paragraph}>
          Welcome to SocialFlick! We’re thrilled to have you on board and excited to be a part of your social media journey.
        </Text>
        <Text style={paragraph}>
          At SocialFlick, we understand the importance of seamless social media management. That's why we've designed our platform to make it incredibly easy for you to upload and share your video content across multiple social media channels with just a few clicks.
        </Text>
        <Text style={paragraphBold}>
          Here’s what you can look forward to with SocialFlick:
        </Text>
        <Text style={listItem}>
          • Multi-Platform Publishing: Easily distribute your video content to various social media platforms simultaneously.
        </Text>
        <Text style={listItem}>
          • Scheduling Tools: Plan and schedule your posts in advance for consistent engagement without the last-minute rush.
        </Text>
        <Text style={listItem}>
          • User-Friendly Interface: Our dashboard is designed for efficiency and ease of use, making your social media management hassle-free.
        </Text>
        <Text style={paragraph}>
          Verify your account and you are set up to use SocialFlick.
        </Text>
        <Section style={btnContainer}>
          <Button style={button} href={link}>
            Get Verified
          </Button>
        </Section>
        <Text style={outro}>
          Thank you for choosing SocialFlick. We’re excited to be a part of your content creation journey and look forward to seeing your success unfold. For any questions, visit our
          <a href="https://www.socialflick.io/help-center" target="_blank" style={linkStyle}>
            Help-Center
          </a>
          or contact us at contact@socialflick.io.
        </Text>

        <Text style={paragraph}>
          Best,
          <br />
          The SocialFlick Team
        </Text>
        <Hr style={hr} />
        <Text style={footer}>
          © SocialFlick
        </Text>
      </Container>
    </Body>
  </Html>
);

export default Welcome;

const main = {
  backgroundColor: "#ffffff",
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
};

const container = {
  margin: "0 auto",
  padding: "20px 0 48px",
};

const logo = {
  margin: "0 auto",
};

const linkStyle = {
  margin: "0px 4px 0px 2px"
}

const paragraph = {
  fontSize: "16px",
  lineHeight: "26px",
};

const paragraphBold = {
  fontSize: "16px",
  lineHeight: "26px",
  fontWeight: "bold"
};

const outro = {
  fontSize: "14px",
  lineHeight: "26px",
  fontStyle: "italic",
  fontFamily: "sans"
};

const btnContainer = {
  textAlign: "center" as const,
};

const button = {
  backgroundColor: "#5F51E8",
  borderRadius: "3px",
  color: "#fff",
  fontSize: "16px",
  textDecoration: "none",
  padding: "12px",
};

const hr = {
  borderColor: "#cccccc",
  margin: "20px 0",
};

const footer = {
  color: "#8898aa",
  fontSize: "12px",
};

const listItem = {
  fontSize: "16px",
  lineHeight: "26px",
  marginBottom: "10px",
  textIndent: "-1em",
  paddingLeft: "1em",
};