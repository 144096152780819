// firebaseUtils.js
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const uploadFileToFirebase = async (file, fileType, onProgressCallback) => {
    const storage = getStorage();

    const folder = fileType === 'img' ? 'thumbnails/' : 'videos/';
    const fileName = new Date().getTime() + file.name;

    const storageRef = ref(storage, folder + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
        const downloadURL = await new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    if (onProgressCallback) {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        onProgressCallback(Math.round(progress));
                    }
                },
                (error) => {
                    console.error('Upload error:', error);
                    reject(error);
                },
                async () => {
                    try {
                        const url = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(url);
                    } catch (error) {
                        console.error('Error getting download URL:', error);
                        reject(error);
                    }
                }
            );
        });

        return downloadURL;

    } catch (error) {
        console.error('Upload failed:', error);
        throw error;
    }
};


export const extractFolderAndFileName = (firebaseUrl) => {
    try {
        // Extract the part of the URL after '/o/'
        const pathWithParams = firebaseUrl.split('/o/')[1];

        // Remove the query parameters after '?' to get only the path
        const path = pathWithParams.split('?')[0];

        // Decode the URL to convert '%2F' to '/'
        const decodedPath = decodeURIComponent(path);

        return decodedPath; // e.g., "videos/1728199587273test.mp4"
    } catch (error) {
        console.error("Failed to extract folder and file name:", error);
        return null;
    }
};