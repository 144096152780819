import { ImCheckmark } from 'react-icons/im';

const Checkboxbtn = ({ name, platform, logo, status, firstSelect }) => {
  const handleImageError = (event, platform) => {
    // If the image fails to load, replace the source with an alternative image
    event.target.src = `../socials/${platform}-logo.svg`; // Replace with the path to your alternative image
  };

  return (
    <div className='flex flex-col'>
      <div className={`text-center truncate w-[50px] md:w-[100px]`}> {/* Adjust max-w-[150px] as needed */}
        {name}
      </div>

      <div className={`social-media-checkbox-style relative w-[50px] md:w-[100px]`}>
        {status && <ImCheckmark size={20} color='green' className='absolute top-[-5px] right-[-5px]' />}
        <img src={!platform ? `../no-thumbnail.png` : `../socials/${platform}-logo.svg`} alt="Creator Avatar" className={`absolute w-[35px] bottom-2 right-0 z-10 ${platform === 'tiktok' && 'bg-white p-1 rounded-full !w-[28px]'}`} />
        <img src={!logo ? '' : `${logo}`} onError={(e) => handleImageError(e, platform)} alt="Creator Avatar" className="z-99 w-full rounded-md" />
      </div>
    </div>
  );
}

export default Checkboxbtn;