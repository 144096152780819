import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { FaInfoCircle, FaRegCalendarCheck } from 'react-icons/fa';
import Textarea from '../../Tools/Textarea';
import Modal from '../../Modal/Modal';
import CheckboxBtn from '../Checkbox/Checkboxbtn'
import axios from 'axios';
import Info from '../../Tools/Info';
import Tabs from '../../Navigation/MenuTabs/Tabs';
import { v4 as uuidv4 } from 'uuid';
import { LuSend } from 'react-icons/lu';
import { formatCustomDateTime } from '../../../utils/date';
import { getFileType } from '../../../utils/getFileType';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const UploadForm = ({ draftFormData, onFormAction, video, uploadType = 'video' }) => {
  const [openModal, setOpenModal] = useState(false);
  const [userSocials, setUserSocials] = useState([]);
  const [atLeastOneSocialSelected, setAtLeastOneSocialSelected] = useState(false);
  const [activateUploadButton, setActivateUploadButton] = useState(false);
  const [isModalScheduleOpen, setIsModalScheduleOpen] = useState(false);

  const [facebookIsChecked, setFacebookIsChecked] = useState(false);
  const [instagramIsChecked, setInstagramIsChecked] = useState(false);
  const [tiktokIsChecked, setTiktokIsChecked] = useState(false);
  const [youtubeIsChecked, setYoutubeIsChecked] = useState(false);
  const broadcastChannel = useMemo(() => new BroadcastChannel('socialsChannel'), []);
  const initialLoad = useRef(true);
  const isUpdatingUserSocials = useRef(true);
  const initialUserSocials = useRef(true);
  const uniquePostId = uuidv4();
  const [deactivatedFormData, setDeactivatedFormData] = useState([]);
  const newFormDataRef = useRef(null);

  const siteMenuInitial = useMemo(() => [
    { id: 'draft', label: 'Draft', icon: null }, // option when no socials connected
    { id: 'tiktok', label: 'TT', icon: null },
    { id: 'youtube', label: 'YT', icon: null },
    { id: 'instagram', label: 'IG', icon: null },
    { id: 'facebook', label: 'FB', icon: null },
  ], []);


  const [siteMenu, setSiteMenu] = useState([]);
  const [activeMenu, setActiveMenu] = useState('draft');

  const [formData, setFormData] = useState([
    {
      uploadTo: 'draft',
      draftId: '',
      platformId: '',
      postId: uniquePostId,
      title: '',
      description: '',
      uploadType: uploadType,
      privacyStatus: 'public',
      commentEnabled: true,
      isContentDisclosed: false,
      duetEnabled: true,
      stitchEnabled: true,
      isBranded: false,
      isOwnBrand: false,
      hasBeenEdited: false,
    }
  ]);

  useEffect(() => {
    if (video.firebasePath) {
      setActivateUploadButton(true);
      // Update formData: set hasBeenEdited to true for all items
      setFormData((prevFormData) => {
        return prevFormData.map((item) => ({
          ...item,
          hasBeenEdited: true,
        }));
      });
    }
  }, [video]);


  const insertDraftMenu = useCallback((data) => {
    const containsDraftStatus = data.some(data => data.status === 'draft');
    if (containsDraftStatus) {
      setSiteMenu(prevSiteMenu => {
        const draftTab = siteMenuInitial.find(menuItem => menuItem.id === 'draft');
        if (draftTab && !prevSiteMenu.some(menuItem => menuItem.id === 'draft')) {
          return [...prevSiteMenu, draftTab];
        }
        return prevSiteMenu;
      });
    }
  }, [siteMenuInitial, setSiteMenu]);

  useEffect(() => {
    if (initialUserSocials.current) {
      getUserSocials();
      initialUserSocials.current = false;
    }
  }, []);

  // GET edit draft data, schedule & draft or re-upload
  useEffect(() => {
    if (initialLoad.current && draftFormData && draftFormData.length > 0) {
      const hasMatchingPlatform = draftFormData.some(draft =>
        userSocials.some(social => social.platform_id === draft.platformId)
      );
      if (hasMatchingPlatform) {
        const updatedDraftFormData = draftFormData.map((data) => ({
          ...data,
          postId: uniquePostId,
        }));
        setFormData(updatedDraftFormData);
        insertDraftMenu(updatedDraftFormData);
        initialLoad.current = false;
      }
    }
  }, [draftFormData, uniquePostId, insertDraftMenu, userSocials]);





  const handleSitieMenuChange = (newTab) => {
    setActiveMenu(newTab);
  };



  useEffect(() => {
    const handleBroadcastMessage = async () => {
      try {
        broadcastChannel.onmessage = async (event) => {
          if (event.data === 'callbackLogin') {
            await getUserSocials();
            setOpenModal(false);
          }
        };
      } catch (error) {
        console.error('Error handling broadcast message:', error);
      }
    };

    handleBroadcastMessage();
  }, [broadcastChannel]);

  useEffect(() => {
    // Retrieve edit draft data from videouploader
    if (isUpdatingUserSocials.current && userSocials.length > 0 && draftFormData.length > 0) {
      isUpdatingUserSocials.current = false;
      const updatedUserSocials = userSocials.map(userSocial => {
        const isMatched = draftFormData.some(upload => upload.platformId === userSocial.platform_id);
        const isDraft = draftFormData.some(upload => upload.uploadTo === 'draft');

        // Only update the checkbox if it has changed
        if (userSocial.platform === 'facebook') {
          setFacebookIsChecked(isMatched);
        }
        if (userSocial.platform === 'tiktok') {
          setTiktokIsChecked(isMatched);
        }

        if (userSocial.platform === 'youtube') {
          setYoutubeIsChecked(isMatched);
        }
        if (userSocial.platform === 'instagram') {
          setInstagramIsChecked(isMatched);
        }

        // Only update these states if they have changed
        if ((isMatched || isDraft)) {
          setAtLeastOneSocialSelected(true);
        }

        return { ...userSocial, checkbox: isMatched };
      });

      // Only setUserSocials if there's an actual update
      if (JSON.stringify(updatedUserSocials) !== JSON.stringify(userSocials)) {

        setUserSocials(updatedUserSocials);
      }
    }
  }, [draftFormData, userSocials, facebookIsChecked, tiktokIsChecked, activateUploadButton]);

  useEffect(() => {
    // Update sitemenu
    if (userSocials.length > 0) {
      const atLeastOneChecked = userSocials.some((social) => social.checkbox === true);
      let isDraft = false;

      if (draftFormData && draftFormData.length > 0) {
        isDraft = draftFormData.some(upload => upload.uploadTo === 'draft');
      }

      let updatedSiteMenu = [];

      if (atLeastOneChecked) {
        updatedSiteMenu = siteMenuInitial
          .filter(menuItem =>
            userSocials.some(social => social.platform === menuItem.id && social.checkbox === true)
          )
          .flatMap(menuItem => {
            // Find all matching social entries for the current menuItem
            const matchedSocials = userSocials.filter(
              social => social.platform === menuItem.id && social.checkbox === true
            );

            // For each matched social, create a new menuItem with the concatenated label
            const matched = matchedSocials.map(social => ({
              ...menuItem, // Spread the existing properties of menuItem
              id: social.platform_id, // Concatenate Label with truncated username
              label: `${menuItem.label} (${social.username.substring(0, 4)}..)` // Concatenate Label with truncated username
            }));
            return matched;
          });
      }
      if (!atLeastOneChecked || isDraft) {
        const draftTab = { id: 'draft', label: 'Draft', icon: null };
        if (!updatedSiteMenu.some(menuItem => menuItem.id === 'draft')) {
          updatedSiteMenu.push(draftTab);
        }
      }

      // Only setSiteMenu if there's an actual update
      if (JSON.stringify(updatedSiteMenu) !== JSON.stringify(siteMenu)) {
        setSiteMenu(updatedSiteMenu);
      }
      const isActiveMenuValid = updatedSiteMenu.some(menuItem => menuItem.id === activeMenu);

      if (!isActiveMenuValid) {
        if (updatedSiteMenu.some(menuItem => menuItem.id === 'draft')) {
          // If 'draft' is available, set activeMenu to 'draft'
          if (activeMenu !== 'draft') {
            setActiveMenu('draft');
          }
        } else if (updatedSiteMenu.length > 0) {
          // Else, set activeMenu to the first item in updatedSiteMenu
          if (activeMenu !== updatedSiteMenu[0].id) {
            setActiveMenu(updatedSiteMenu[0].id);
          }
        } else {
          // Handle the case where updatedSiteMenu is empty
          if (activeMenu !== '') {
            setActiveMenu('');
          }
        }
      }
    }
  }, [draftFormData, userSocials, siteMenuInitial, activeMenu, siteMenu]);

  const getUserSocials = async () => {
    try {
      const response = await axios.get(BACKEND_API_URL + '/api/auth/user/social_media_tokens', {
        withCredentials: true,
      });

      response.data.forEach((item) => {
        item.checkbox = false;
      });

      let allowedPlatforms = response.data;

      if (uploadType === 'post') {
        allowedPlatforms = response.data.filter(
          (item) =>
            item.platform === 'facebook' || item.platform === 'instagram'
        );
      } else {
        allowedPlatforms = response.data;
      }


      for (const social of response.data) {
        if (social.platform === 'facebook') {
          setFacebookIsChecked(social.checkbox);
        }
        if (social.platform === 'tiktok') {
          setTiktokIsChecked(social.checkbox);
        }
        if (social.platform === 'youtube') {
          setYoutubeIsChecked(social.checkbox);
        }
        if (social.platform === 'instagram') {
          setInstagramIsChecked(social.checkbox);
        }
      }
      setUserSocials(allowedPlatforms);
      return response.data;
    } catch (error) {
      console.error('Error fetching social media tokens:', error);
    }
  };

  const closeModal = (action) => {
    if (action instanceof Date) {
      const newFormData = formData.map((data) => ({
        ...data,
        schedule: action,
      }));
      setFormData(newFormData)
      newFormDataRef.current = newFormData;
    }


    setOpenModal(false);
    setIsModalScheduleOpen(false);
  };

  // Monitor changes in formData to execute handleActionClick when the new startDate is set from modal
  // this ensures that it handles action after its set
  useEffect(() => {
    if (newFormDataRef.current) {
      handleActionClick('schedule');
      newFormDataRef.current = null; // Clear the ref after action is executed
    }

    //console.log(formData);
  }, [formData]);


  const handleFormDataChange = (platform, newFormData) => {
    setFormData(prevFormData =>
      prevFormData.map(data => {
        if (data.platformId === platform) {
          return { ...data, ...newFormData, hasBeenEdited: true };
        } else {
          return data;
        }
      })
    );
  };

  const handleActionClick = (action) => {
    let editedFormData = formData.filter(
      (data) => data.hasBeenEdited /* && data.status !== 'failed' */ // Uncomment if needed
    );

    // If editedFormData contains 2 or more objects, remove items where uploadTo === 'draft'
    if (editedFormData.length >= 2) {
      editedFormData = editedFormData.filter((data) => data.uploadTo !== 'draft');
    }

    // Proceed with the action using the filtered editedFormData
    onFormAction(action, editedFormData);
  };


  const socialCheckboxesActivate = (userSocial) => {
    //update usersocials on checkboxbtn click
    setUserSocials((prevUserSocials) => {
      const updatedUserSocials = prevUserSocials.map((social) => {
        if (social.platform_id === userSocial.platform_id) {
          return { ...social, checkbox: !social.checkbox };
        }
        return social;
      });

      const atLeastOneChecked = updatedUserSocials.some((social) => social.checkbox === true);
      setAtLeastOneSocialSelected(atLeastOneChecked);

      if (userSocial.platform === 'facebook') {
        setFacebookIsChecked(!userSocial.checkbox);
      }
      if (userSocial.platform === 'tiktok') {
        setTiktokIsChecked(!userSocial.checkbox);
      }
      if (userSocial.platform === 'youtube') {
        setYoutubeIsChecked(!userSocial.checkbox);
      }

      if (userSocial.platform === 'instagram') {
        setInstagramIsChecked(!userSocial.checkbox);
      }


      // Update formData
      setFormData((prevFormData) => {
        // Handle checkbox activation
        if (!userSocial.checkbox) {
          const existingData = deactivatedFormData.find(data => data.platformId === userSocial.platform_id);
          if (existingData) {
            // If there was a previous deactivated social, activate it with previous values
            const existingObj = prevFormData?.find(data => data && data.platformId === userSocial.platform_id);
            if (!existingObj) {
              prevFormData = [...prevFormData, existingData];
              setDeactivatedFormData(prev => prev.filter(data => data.platformId !== userSocial.platform_id));
            }
          } else {
            // If no previous social form, create a new
            const existingObj = prevFormData?.find(data => data && data.platformId === userSocial.platform_id);
            if (!existingObj) {
              prevFormData = [...prevFormData, {
                uploadTo: userSocial.platform,
                draftId: '',
                postId: uniquePostId,
                title: '',
                description: '',
                uploadType: uploadType,
                privacyStatus: 'public',
                commentEnabled: true,
                isContentDisclosed: false,
                duetEnabled: true,
                stitchEnabled: true,
                isBranded: false,
                isOwnBrand: false,
                hasBeenEdited: false,
                platformId: userSocial.platform_id
              }];
            }
          }
        } else { // Handle checkbox deactivation
          const dataToDeactivate = prevFormData.find(data => data.uploadTo === userSocial.platform);
          if (dataToDeactivate) {
            setDeactivatedFormData(prev => [...prev, dataToDeactivate]);
            prevFormData = prevFormData.filter(data => data.platformId !== userSocial.platform_id);
          }
        }

        // Ensure there's always at least one object in formData
        if (prevFormData.length === 0) {
          prevFormData.push({
            uploadTo: 'draft',
            draftId: '',
            postId: uniquePostId,
            title: '',
            description: '',
            uploadType: uploadType,
            privacyStatus: 'public',
            commentEnabled: true,
            isContentDisclosed: false,
            duetEnabled: true,
            stitchEnabled: true,
            isBranded: false,
            isOwnBrand: false,
            hasBeenEdited: false,
            platformId: '',
          });
        }

        prevFormData = prevFormData.map((data) => ({
          ...data,
          postId: uniquePostId,
        }));

        return prevFormData;
      });
      return updatedUserSocials;
    });
  };

  const findPlatformData = (platform) => {
    return formData.find(data => data.platformId === platform) || formData[0];
  };

  return (
    <div className='upload-form h-full'>
      {/* SOCIAL MEDIA CHECKBOXES */}
      <div className={`flex flex-col dashboard-section mb-5 !w-max !md:w-full !shadow-sm`}>
        <div className='flex justify-between'>
          <p>Select your socials</p>

          <div className='border-dashed border-2 border-gray-300 text-[8px] py-2 px-1  transition-all duration-300 hover:border-solid rounded-lg cursor-pointer hover:border-blue-300' onClick={(e) => setOpenModal(true)}>
            Connect
          </div>
        </div>
        <div className={`flex overflow-x-auto overflow-y-hidden py-2`}>
          {userSocials.length > 0 && userSocials.map((socialMedia, index) => (
            <div
              key={index}
              className={`${socialMedia.checkbox ? 'opacity-100' : 'opacity-20'} ${!socialMedia.checkbox && 'animate-custom-pulse animate-custom-pulse-delay'} mr-5 relative transition-transform transform hover:scale-105 `}
              onClick={(e) => socialCheckboxesActivate(socialMedia)}
            >
              <CheckboxBtn
                name={socialMedia.username}
                platform={socialMedia.platform}
                logo={socialMedia.logo}
                status={socialMedia.checkbox}
                todayButton="Today"
              />
            </div>
          ))}
        </div>
      </div>

      <div className={`dashboard-section !shadow-sm ${atLeastOneSocialSelected || userSocials.length === 0 ? '' : 'opacity-40 cursor-not-allowed pointer-events-none'}`}>
        <div className="flex flex-col relative mb-2">
          <Tabs tabs={siteMenu} onTabChange={handleSitieMenuChange} activeTab={activeMenu} main={true} />
        </div>

        <Form
          formData={findPlatformData(activeMenu)}
          onFormDataChange={(newFormData) => handleFormDataChange(activeMenu, newFormData)}
          youtubeIsChecked={youtubeIsChecked}
          tiktokIsChecked={tiktokIsChecked}
          facebookIsChecked={facebookIsChecked}
          instagramIsChecked={instagramIsChecked}
          userSocials={userSocials}
        />

        <div className='flex border-t-2 pt-3 relative items-end'>
          <div className='flex flex-col items-center mr-5'>
            {activateUploadButton === false && atLeastOneSocialSelected === false && (
              <div className='group'>
                <Info type="onlyDraftOption" />
              </div>
            )}
          </div>

          <div className='flex gap-2 items-end justify-end w-full'>
            <div
              className={`border-b border-gray-200 text-sm px-2 text-gray-700 ${true ? 'hover:border-blue-300 cursor-pointer' : 'cursor-not-allowed opacity-50'}`}
              onClick={() => {
                if (true) {
                  handleActionClick('draft');
                }
              }}
            >
              <span>Save Draft</span>
            </div>
            <div
              className={`flex gap-1 items-center bg-blue-500 text-white text-sm md:text-md font-semibold px-4 py-2 rounded-lg ${uploadType === 'post' || (activateUploadButton && atLeastOneSocialSelected && userSocials?.length > 0) ? 'hover:bg-blue-300 cursor-pointer' : 'cursor-not-allowed bg-gray-300'}`}
              onClick={(e) => {
                if (uploadType === 'post' || (activateUploadButton && atLeastOneSocialSelected && userSocials?.length > 0)) {
                  setIsModalScheduleOpen(true);
                }
              }}
            >
              {formData[0].schedule ? formatCustomDateTime(formData[0].schedule) : 'Schedule'}
              <FaRegCalendarCheck />
            </div>
            <div
              className={`flex gap-1 items-center bg-blue-500 px-4 py-2 text-sm md:text-md font-semibold text-white rounded-lg ${uploadType === 'post' || (activateUploadButton && atLeastOneSocialSelected && userSocials?.length > 0) ? 'hover:bg-blue-300 cursor-pointer' : 'cursor-not-allowed bg-gray-300'}`}
              onClick={(e) => {
                if (uploadType === 'post' || (activateUploadButton && atLeastOneSocialSelected && userSocials?.length > 0)) {
                  handleActionClick('upload');
                }
              }}
            >
              <span>Publish</span>
              <LuSend />
            </div>
          </div>


          <div className='relative ml-2'>
            {tiktokIsChecked && formData.isContentDisclosed === true && !formData.isOwnBrand && !formData.isBranded && (
              <p className="text-red-500 text-[10px] absolute w-[135px]">You need to indicate if your content promotes yourself or a third party</p>
            )}
          </div>
        </div>

        {tiktokIsChecked && formData.uploadTo === 'tiktok' && (
          <div className="text-[11px] mt-2">
            {!formData.isBranded ? (
              <span>* By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank" rel="noreferrer" style={{ color: "#FE2C55" }}>Music Usage Confirmation</a></span>
            ) : (
              <span>* By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/bc-policy/en" target="_blank" rel="noreferrer" style={{ color: "#FE2C55" }}>Brand Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank" rel="noreferrer" style={{ color: "#FE2C55" }}>Music Usage Confirmation</a></span>
            )}
          </div>
        )}
      </div>

      {isModalScheduleOpen && (
        <div className='flex-col items-center border-t-2 pt-3 relative'>
          <Modal open={isModalScheduleOpen} type={'ScheduledModal'} otherInfo={formData[0].schedule ? new Date(formData[0].schedule) : new Date()} closeModal={closeModal} />
        </div>
      )}
      {openModal && (
        <div className='flex-col items-center border-t-2 absolute'>
          <Modal open={openModal} closeModal={closeModal} type={'ConnectSocialsModal'} otherInfo={'hashtag'} />
        </div>
      )}
    </div>
  );
};



const Form = ({
  formData,
  onFormDataChange,
  youtubeIsChecked,
  facebookIsChecked,
  instagramIsChecked,
  tiktokIsChecked,
  userSocials,
}) => {
  const handleInputChange = (field, value) => {
    const newFormData = { [field]: value };
    onFormDataChange(newFormData);
  };

  const handleDescriptionChange = (value) => {
    handleInputChange('description', value);
  };

  const toggleDisclosure = () => {
    if (!formData.isContentDisclosed) {
      handleInputChange('isOwnBrand', false);
      handleInputChange('privacyStatus', false);
    }
    handleInputChange('isContentDisclosed', !formData.isContentDisclosed);
  };

  const toggleBranded = () => {
    handleInputChange('isBranded', !formData.isBranded);
    if (!formData.isBranded) {
      handleInputChange('privacyStatus', 'public');
    }
  };
  return (
    <form onClick={(e) => e.stopPropagation()} className=''>
      <div className={``}>
        {(getFileType(formData.uploadType) === 'video') && (formData.uploadTo === 'facebook' || formData.uploadTo === 'youtube') && (
          <label className="form-label px-10 pt-2">
            <input
              type="text"
              value={formData.title}
              onChange={(e) => handleInputChange('title', e.target.value)}
              className="form-input"
              placeholder={`Title (Only for youtube videos and facebook video posts)`}
            />
          </label>
        )}
        {/* TEXT AREA */}
        <div className='px-10 py-2'>
          <Textarea onDescriptionChange={handleDescriptionChange} initialDescription={formData.description} placeholder={formData.uploadTo === 'youtube' ? 'description' : 'caption'} />
        </div>

        {/* TIKTOK PRIVACY SETTINGS */}
        <div className='flex w-full gap-5'>
          <label className="flex flex-col font-semibold justify-start items-start mt-5 flex-1 text-sm font-medium text-gray-900">
            Visibility:
            <select
              value={formData.privacyStatus}
              onChange={(e) => handleInputChange('privacyStatus', e.target.value)}
              className="form-select w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm"
            >
              <option value="private">Private</option>
              <option value="public">Public</option>
              {formData.uploadTo === 'youtube' && (
                <option value="private">Unlisted</option>
              )}
            </select>
          </label>

          {getFileType(formData.uploadType) === 'video' && formData.uploadTo === 'facebook' && (
            <label className="flex flex-col font-semibold justify-start items-start mt-5 w-1/2 text-sm font-medium text-gray-900">
              Type:
              <select
                value={formData.uploadType}
                onChange={(e) => handleInputChange('uploadType', e.target.value)}
                className="form-select w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm"
              >
                <option value="video">Video Post</option>
                <option value="reel">Reel</option>
              </select>
            </label>
          )}

          {getFileType(formData.uploadType) === 'video' && formData.uploadTo === 'instagram' && (
            <label className="flex flex-col font-semibold justify-start items-start mt-5 w-1/2 text-sm font-medium text-gray-900">
              Type:
              <select
                value={formData.uploadType}
                onChange={(e) => handleInputChange('uploadType', e.target.value)}
                className="form-select w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm"
              >
                <option value="REELS">Reels</option>
                <option value="STORIES">Story</option>
              </select>
            </label>
          )}
        </div>

        {/* TIKTOK ALLOW USERS CHECKBOX */}
        {formData.uploadTo === 'tiktok' && (
          <div className='flex flex-col py-3 bg-gray-200 rounded-lg px-2'>
            <div className='flex justify-between'>
              <p className='font-semibold mr-3'>Allow users to:</p>
              <div className='group'>
                <Info type="tiktokFeatures" />
              </div>
            </div>

            {userSocials && userSocials.map((item, index) => (
              <>
                {item.platform === 'tiktok' && (
                  <div className='flex space-x-3' key={item.id}>
                    <label className=''>
                      <input
                        type="checkbox"
                        checked={formData.commentEnabled}
                        onChange={(e) => handleInputChange('commentEnabled', e.target.checked)}
                      />
                      Comment
                    </label>
                    <br />
                    <label className={`relative group`}>
                      <input
                        type="checkbox"
                        checked={formData.duetEnabled}
                        onChange={(e) => handleInputChange('duetEnabled', e.target.checked)}
                      />
                      Duet
                    </label>
                    <br />
                    <label className={`relative group`}>
                      <input
                        type="checkbox"
                        checked={formData.stitchEnabled}
                        onChange={(e) => handleInputChange('stitchEnabled', e.target.checked)}
                        className={`item`}
                      />
                      <span>Stitch</span>
                    </label>
                  </div>
                )}
              </>
            ))}

            <div className='flex flex-col mt-5'>
              <div className='flex relative'>
                <p className='flex-1 font-semibold'>Disclose video content:</p>
                <label className="switch relative">
                  <input type="checkbox" onChange={toggleDisclosure} checked={formData.isContentDisclosed} className="sr-only" />
                  <div className={`w-12 h-6 rounded-full shadow-md bg-gray-400 ${formData.isContentDisclosed && 'bg-green-500'}`}>
                    <div
                      className={`indicator w-6 h-6 rounded-full bg-white transform ${formData.isContentDisclosed ? 'translate-x-full' : 'translate-x-0'} transition duration-300 ease-in-out`}
                    ></div>
                  </div>
                </label>
              </div>
              <div>
                <p className='text-[10px]'>Turn on to disclose that this video promotes goods or services.</p>
              </div>
              {formData.isContentDisclosed && (
                <div className='flex flex-col transition duration-300'>
                  <div className='flex bg-blue-300 p-2 m-2 rounded-lg text-[12px]'>
                    <FaInfoCircle size={20} className='mr-2' />
                    <span>Your video will be labeled <span className='underline font-semibold'>Promotional Content</span>. This cannot be changed once your video is posted!</span>
                  </div>
                  <div className='flex-col w-[80%] mx-auto'>
                    <label className="flex">
                      <span className='flex-1 font-semibold'>Your Brand</span>
                      <input type="checkbox" onChange={(e) => handleInputChange('isOwnBrand', e.target.checked)} checked={formData.isOwnBrand} />
                    </label>
                    <p className='text-[12px]'>You are promoting yourself or your own business/brand. This video will be classified as <span className='font-semibold underline'>Brand Organic</span></p>
                  </div>
                  <div className='flex-col w-[80%] mx-auto my-3'>
                    <label className="flex">
                      <span className='flex-1 font-semibold'>Branded Content</span>
                      <input type="checkbox" onChange={toggleBranded} checked={formData.isBranded} />
                    </label>
                    <p className='text-[12px]'>You are promoting another brand or a third party. This video will be classified as <span className='font-semibold underline'>Branded Content</span>. <span className='text-red-300 underline'>Privacy setting will be set to public!</span></p>
                  </div>
                  <div>
                    <p className='text-[10px]'>* Your photo/video will be labeled as <span className='font-semibold underline'>Paid Partnership</span></p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default UploadForm;
