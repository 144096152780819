import React, { useState, useEffect } from 'react';

const CustomRadioButtons = ({ content, onSelectionChange }) => {
    const [selectedValue, setSelectedValue] = useState(content[0]?.label);

    useEffect(() => {
        onSelectionChange(selectedValue);
    }, [selectedValue, onSelectionChange]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };

    return (
        <>
            <fieldset className="grid grid-cols-2 gap-4">
                <legend className="sr-only">Delivery</legend>
                {content.map((item) => (
                    <div key={item.label}>
                        <label
                            htmlFor={item.label}
                            className="flex cursor-pointer justify-between gap-4 rounded-lg border border-gray-100 bg-white py-4 px-8 text-sm font-medium shadow-sm transition-all duration-500 hover:border-gray-200 has-[:checked]:border-blue-500 has-[:checked]:ring-1 has-[:checked]:ring-blue-500"
                        >
                            <div>
                                <p className="text-gray-700">{item.label}</p>
                                {item.description && (
                                    <p className="mt-1 text-gray-900">{item.description}</p>
                                )}
                            </div>
                            <input
                                type="radio"
                                name="DeliveryOption"
                                value={item.label}
                                id={item.label}
                                className={`${item.showCheckobox ? 'size-5 border-gray-300 text-blue-500' : 'sr-only'}`}
                                checked={selectedValue === item.label}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                ))}
            </fieldset>
        </>
    );
};

export default CustomRadioButtons;
