import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.min.css';
import Hotjar from '@hotjar/browser';

Hotjar.init(5160916, 6);

const root = ReactDOM.createRoot(document.getElementById('root'));
const isLocalhost = window.location.hostname === 'localhost';

root.render(
  isLocalhost ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  )
);