import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoIosClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import CustomInput from '../../Tools/Input/CustomInput.js';
import { RiLockPasswordLine } from 'react-icons/ri';
import { FaCheck } from 'react-icons/fa';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;


const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(false);
  const navigate = useNavigate();
  const notLoggedIn = false;

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    code: ''
  });
  const { email, password, code } = formData;

  // Pass Rules
  const [num, setNum] = useState(false)
  const [passLength, setPassLength] = useState(false)

  useEffect(() => {
    // Check for number
    if (password.match(/([0-9])/) && password.match(/([0-9])/)) {
      setNum(true);
    } else {
      setNum(false);
    }

    // Check for password length
    if (password.length >= 6) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password])

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      var sendEmail = email;

      //cant get user, since not logged in. Get EMAIL FROM STEP ONE
      if (!notLoggedIn) {
        const getEmail = await axios.get(BACKEND_API_URL + '/api/auth/user/getUser', {
          withCredentials: true,
        });
        sendEmail = getEmail.data.email;
      }
      const changePass = await axios.patch(BACKEND_API_URL + '/api/auth/changepassword/newpassword', { email: sendEmail, password, code }, {
        withCredentials: true, // Include this option if you need cookies
      });
      if (notLoggedIn) {
        setResponseMessage('<p className="--success-message">Successfull changed your password, you will be redirected to the login page, or click the <Link to="/login">Login</Link></p>');
        navigate('/login')
      }
      setResponseMessage('<p className="--success-message">Successfull changed your password!</p>');

      setIsLoading(false);
      return changePass.data; // Return the data for chaining
    } catch (error) {
      console.log(error);
      setResponseMessage(`<p className="--error-message">${error.response.data.error}</p>`);
    }
  };



  return (
    <div className="auth-form-container">
      <div className="auth-form">
        <form onSubmit={handleSubmit}>
          <div>
            <p className='text-center'>Enter the verification code that you received in your email and your choose your new password:</p>
            <div className='password-input-container mb-2 mt-5'>

              <CustomInput
                type="text"
                name="code"
                value={code}
                onChange={handleChange}
                placeholder="Type your verification code"
                Icon={FaCheck}
              />
            </div>

            <CustomInput
              type="password"
              name="password"
              value={password}
              onChange={handleChange}
              placeholder="Type your new password"
              Icon={RiLockPasswordLine}
            />

            {/* Password Strength */}
            {password.length > 0 && !num && (
              <div className='passwordStrength'>
                <IoIosClose color='red' fontSize={30} /> Has to include a number
              </div>
            )}
            {password.length > 0 && !passLength && (
              <div className='passwordStrength'>
                <IoIosClose color='red' fontSize={30} /> At least 6 chars
              </div>
            )}
            <div className='flex justify-end mt-3'>
              <button
                className={`upload-button  ${isLoading ? 'disabled' : ''} mt-2`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="loading-animation"></div>
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
            {responseMessage && (<div dangerouslySetInnerHTML={{ __html: responseMessage }}></div>)}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;