import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { MdCloudUpload } from 'react-icons/md';
import { BsInstagram, BsYoutube, BsTiktok, BsFacebook, BsThreeDotsVertical } from 'react-icons/bs';
import { RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import Modal from '../Modal/Modal';
import DropdownButton from '../Tools/DropdownButton';

const Week = ({ week, userUploads, showHistory, showScheduled, refreshUserUploads }) => {
    return (
        <div className="flex-1 overflow-x-auto">
            <div className="grid grid-cols-7 min-w-[700px]">
                {week.map((day, idx) => (
                    <Day
                        day={day}
                        key={idx}
                        userUploads={userUploads}
                        showHistory={showHistory}
                        showScheduled={showScheduled}
                        refreshUserUploads={refreshUserUploads}
                    />
                ))}
            </div>
        </div>
    );
};
export default Week;

const Day = ({ day, userUploads, showHistory, showScheduled, refreshUserUploads }) => {
    const [showModal, setShowModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [intervalUploads, setIntervalUploads] = useState({});
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [clickedData, setClickedData] = useState({
        date: null,
        draft: null,
    });
    const [deletedPost, setDeletedPost] = useState(null);

    const intervals = useMemo(() => [
        { start: 0, end: 6 },
        { start: 6, end: 12 },
        { start: 12, end: 18 },
        { start: 18, end: 24 },
    ], []);

    useEffect(() => {
        if (userUploads && userUploads.length > 0) {
            const uploadsByInterval = {};
            intervals.forEach((interval) => {
                uploadsByInterval[`${interval.start}-${interval.end}`] = [];
            });

            userUploads.forEach((upload) => {
                upload.posts.forEach((post) => {
                    const uploadDate = dayjs(post.schedule || post.updatedAt);
                    if (
                        uploadDate.year() === day.year() &&
                        uploadDate.month() === day.month() &&
                        uploadDate.date() === day.date()
                    ) {
                        const hour = uploadDate.hour();
                        const matchingInterval = intervals.find(
                            (interval) => hour >= interval.start && hour < interval.end
                        );
                        if (matchingInterval) {
                            const key = `${matchingInterval.start}-${matchingInterval.end}`;
                            uploadsByInterval[key].push({ upload, post });
                        }
                    }
                });
            });
            setIntervalUploads(uploadsByInterval);
        }
    }, [userUploads, day, intervals]);

    const getCurrentDayClass = () => {
        return day.isSame(dayjs(), 'day') ? 'text-white font-semibold rounded-full px-2 bg-blue-500' : '';
    };

    const isPastDay = () => {
        return day.isBefore(dayjs(), 'day');
    };

    const closeModal = () => {
        setShowModal(false);

        if (isDeleteModalOpen === true) {
            setDeleteModalOpen(false);
            refreshUserUploads();
        }

        if (showModal === true) {
            setShowModal(false);
            refreshUserUploads();
        }
    };

    const IconForSocialMedia = ({ socialMedia }) => {
        switch (socialMedia) {
            case 'youtube':
                return <BsYoutube color="red" size={13} />;
            case 'instagram':
                return <BsInstagram color="#C13584" size={13} />;
            case 'tiktok':
                return <BsTiktok color="black" size={13} />;
            case 'facebook':
                return <BsFacebook color="blue" size={13} />;
            default:
                return null;
        }
    };

    const handleUploadButtonClick = () => {
        setShowModal(true);
        setClickedData({ date: day.toDate() });
    };

    const handleEditButtonClick = (draft) => {
        setClickedData({ draft });
        setShowModal(true);
    };

    const handleDeleteButtonClick = (upload) => {
        setDeleteModalOpen(true);
        setDeletedPost(upload);
    };

    return (
        <div
            className={`relative border min-h-[1300px] border-gray-200 flex flex-col h-full ${isPastDay()
                ? 'opacity-30 bg-gray-200 border-gray-300 shadow-inner'
                : 'lg:hover:border-gray-400'
                }`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <header className="flex flex-col items-center my-1">
                <p className={`text-sm text-center ${getCurrentDayClass()}`}>{day.format('ddd DD')}</p>
            </header>

            {/* Main container that divides the day into four equal intervals */}
            <div className="flex-1 flex flex-col">
                {intervals.map((interval) => {
                    const key = `${interval.start}-${interval.end}`;
                    const uploads = intervalUploads[key] || [];

                    return (
                        <div key={key} className="flex-1 border-t border-gray-300 pt-1 overflow-y-auto">
                            <p className="text-xs font-semibold text-gray-600 px-2">
                                {`${interval.start.toString().padStart(2, '0')}:00 - ${interval.end
                                    .toString()
                                    .padStart(2, '0')}:00`}
                            </p>
                            {uploads.length > 0 && (
                                uploads.map(({ upload, post }, index) => (
                                    <div key={`${index}`} className={`flex w-[95%] min-h-[50px] max-h-[50px] mx-auto rounded-lg mb-2 cursor-pointer ${upload.status === 'scheduled' ? 'bg-yellow-300 hover:bg-yellow-500' : 'bg-green-200 hover:bg-green-500'}  ${upload.status === 'scheduled' && !showScheduled && 'hidden'} ${upload.status === 'uploaded' && !showHistory && 'hidden'}`}>
                                        <div className='flex w-full p-1'>
                                            <div className='flex flex-col flex-1'>
                                                {upload.posts[0].title && (
                                                    <p className="text-xs mx-1 text-black font-bold truncate ">{upload.posts[0].title}</p>
                                                )}

                                                <div className='flex-1'>
                                                    {upload.posts[0].description && (
                                                        <p className="text-black text-xs mx-1 truncate">{upload.posts[0].description}</p>
                                                    )}
                                                </div>


                                            </div>
                                            <div className='flex flex-col justify-between'>
                                                {true && ( /*upload.status === 'scheduled' && upload.posts[0].schedule */
                                                    <DropdownButton label={<BsThreeDotsVertical size={18} />} type={'singleLeftItems'} classes="!p-0 hover:bg-none" options={[
                                                        { label: `Edit`, onClick: () => handleEditButtonClick(upload), icon: <RiPencilLine size={20} className="ml-5 text-blue-600 font-bold" /> },
                                                        { label: `Delete`, onClick: () => handleDeleteButtonClick(upload), icon: <RiDeleteBinLine size={20} className="ml-5 text-blue-600 font-bold" /> },
                                                    ]} />
                                                )}
                                                <div className='flex justify-end gap-1'>
                                                    {upload.posts.map((post, i) => (
                                                        <IconForSocialMedia socialMedia={post.uploadTo} key={i} />
                                                    ))}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    );
                })}
            </div>

            {isHovered && !isPastDay() && (
                <div
                    className="flex border-t border-gray-300 cursor-pointer text-[12px] justify-center items-center hover:text-blue-500 absolute bottom-0 bg-white w-full py-1"
                    onClick={handleUploadButtonClick}
                >
                    Upload <MdCloudUpload size={20} color="black" className="ml-2" />
                </div>
            )}

            {showModal && (
                <Modal
                    open={showModal}
                    closeModal={closeModal}
                    type={'VideoUploaderModal'}
                    otherInfo={clickedData}
                />
            )}
            {isDeleteModalOpen && (
                <Modal
                    open={isDeleteModalOpen}
                    closeModal={closeModal}
                    type={'VerifyDelete'}
                    otherInfo={deletedPost}
                />
            )}
        </div>
    );
};