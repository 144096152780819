import {
    Body,
    Button,
    Container,
    Head,
    Hr,
    Html,
    Img,
    Preview,
    Section,
    Text,
} from "@react-email/components";
import * as React from "react";

interface EmailVerifyProps {
    username: string;
    link: string;
}


export const EmailVerify = ({
    link,
    username
}: EmailVerifyProps) => (
    <Html>
        <Head />
        <Preview>
            Verify your SocialFlick account.
        </Preview>
        <Body style={main}>
            <Container style={container}>
                <Img
                    src={`https://app.socialflick.io/logo/logo-vertical-black.png`}
                    width={"50%"}
                    alt="SocialFlick"
                    style={logo}
                />
                <Text style={paragraph}>Hello {username}</Text>
                <Text style={paragraph}>
                    Thanks for signing up with SocialFlick! We're excited to have you onboard and eager to help you streamline your social media management.
                </Text>
                <Text style={paragraph}>
                    Get started with your social media content management by verifying your account. This will give you full access to all our features!
                </Text>
                <Section style={btnContainer}>
                    <Button style={button} href={link}>
                        Get Verified
                    </Button>
                </Section>
                <Text style={paragraph}>
                    Best,
                    <br />
                    The SocialFlick Team
                </Text>
                <Hr style={hr} />
                <Text style={footer}>
                    © SocialFlick
                </Text>
            </Container>
        </Body>
    </Html>
);

EmailVerify.PreviewProps = {
    link: "Alan",
    username: "alan"
} as EmailVerifyProps;

export default EmailVerify;

const main = {
    backgroundColor: "#ffffff",
    fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
};

const container = {
    margin: "0 auto",
    padding: "20px 0 48px",
};

const logo = {
    margin: "0 auto",
};

const paragraph = {
    fontSize: "16px",
    lineHeight: "26px",
};

const btnContainer = {
    textAlign: "center" as const,
};

const button = {
    backgroundColor: "#5F51E8",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "16px",
    textDecoration: "none",
    textAlign: "center" as const,
    display: "block",
    padding: "12px",
};

const hr = {
    borderColor: "#cccccc",
    margin: "20px 0",
};

const footer = {
    color: "#8898aa",
    fontSize: "12px",
};
