export const getFileType = (uploadType) => {
    if (!uploadType) return 'unknown';


    // Define the different sets of types
    const videoTypes = ['video', 'reel', 'REELS', 'STORIES'];
    const imageTypes = ['post', 'image', 'img'];

    // Check if the uploadType matches any video type
    if (videoTypes.includes(uploadType.toLowerCase())) {
        return 'video';
    }

    // Check if the uploadType matches any image type
    if (imageTypes.includes(uploadType.toLowerCase())) {
        return 'image';
    }

    // Return a default type or handle an unknown uploadType case
    return 'unknown';
}
