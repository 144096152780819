import React, { useState, useRef, useEffect, useCallback } from 'react';
import './VideoUploader.css'; // Import the CSS file for styling
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import '../../firebase.js'
import { toast } from 'react-toastify';

import VideoPreview from './Preview/Preview';
import UploadForm from './UploadForm/UploadForm';
import sendEmail from '../Tools/SendEmail';
import VideoErrorUpload from '../../emails/VideoErrorUpload.tsx';
import { render } from '@react-email/components';
import { extractFolderAndFileName, uploadFileToFirebase } from '../../utils/firebase.js';
import { getFileType } from '../../utils/getFileType.js';
import { v4 as uuidv4 } from 'uuid';
import { checkImagePropertie } from '../../utils/metadataCheck.js';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const VideoUploader = ({ draft, closeScheduleModal, uploadType }) => {
  const navigate = useNavigate();
  const hasRunOnceRef = useRef(false);
  const [localVideoPath, setLocalVideoPath] = useState(null);
  const [firebaseVideoPath, setFirebaseVideoPath] = useState(null);
  const [firebaseThumbPath, setFirebaseThumbPath] = useState(null);
  const [formData, setFormData] = useState({});
  const uniquePostId = uuidv4();

  //uploaded video status
  const handleEditDraft = useCallback(async (group) => {
    const updatedFormData = group.posts.map((data) => {
      const post = group.posts.find(post => post.uploadTo === data.uploadTo);

      if (post) {
        const draftScheduleDate = new Date(post.schedule);
        if (draftScheduleDate > new Date()) {
          post.schedule = draftScheduleDate;
        }

        const newFormData = {
          draftId: post._id,
          postId: post.post_id,
          platformId: post.platform_id,
          title: post.title,
          description: post.description,
          privacyStatus: post.privacy_status,
          schedule: post.schedule,
          uploadType: post.uploadType,
          uploadTo: post.uploadTo,
          status: post.status,
          firebasePath: post.firebase_path,
          videoPath: post.video_path,
          hasBeenEdited: true,
        };
        setFirebaseThumbPath(post.thumbnail);
        setFirebaseVideoPath(post.firebase_path);
        setLocalVideoPath(post.video_path);
        return newFormData;
      } else {
        return data;
      }
    });
    setFormData(updatedFormData);
  }, []);


  useEffect(() => {
    if (!hasRunOnceRef.current && draft) {
      //If its object its an scheduled draft, otherwise its a timestamp
      if (draft.posts.length > 0) {
        handleEditDraft(draft)
      }
      hasRunOnceRef.current = true;
    }
  }, [draft, handleEditDraft]);



  /**
   * @function handleUploadButtonClick
   * UPLOAD button - Download it to server and upload to Social Medias.
   *
   */
  const handleUploadButtonClick = async (uploadData) => {
    if (uploadData.uploadTo === 'draft') return;

    const uploadPromises = [];
    let thumb = formData.thumbnail;

    try {
      if (getFileType(uploadData.uploadType) === 'video' && (!localVideoPath && !uploadData.videoPath)) {
        toast.warning('No video uploaded...');
        return;
      }

      if (getFileType(uploadData.uploadType) === 'image' && (!localVideoPath && !uploadData.videoPath && uploadData.uploadTo === 'instagram')) {
        toast.warning('An image is required for instagram uploads.');
        return;
      }

      if (thumb && typeof thumb !== 'string') {
        thumb = await uploadFileToFirebase(thumb, 'img');
      }
      //check if social.checkbox == true which means that the user has checked the checkbox at videoUploader.
      if (uploadData.uploadTo) {
        switch (uploadData.uploadTo) {
          case 'youtube':
            uploadPromises.push(uploadToYoutube(uploadData));
            break;
          case 'tiktok':
            uploadPromises.push(uploadToTiktok(uploadData));
            break;
          case 'instagram':
            uploadPromises.push(uploadToInstagram(uploadData));
            break;
          case 'facebook':
            uploadPromises.push(uploadToFacebook(uploadData));
            break;
          // Add more cases for other platforms if needed
          default:
            break;
        }
      }
      navigate('/dashboard');
      const toastId = toast.loading('Your content is being uploaded to ' + uploadData.uploadTo)

      // Run all uploads concurrently
      let promises = await Promise.all(uploadPromises);
      // Process each result
      promises.forEach((result) => {
        if (result.success) {
          toast.update(toastId, {
            render: result.message,
            type: 'success',
            isLoading: false,
            autoClose: 5000,
          });
        } else {
          toast.update(toastId, {
            render: result.message,
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        }
      });

    } catch (error) {
      console.error('UPLOAD API ERROR:', error);
    }
  };

  /**
   * 
   * UPLOAD TO SOCIAL MEDIAS API
   */

  const uploadToYoutube = async (uploadData) => {
    if (!localVideoPath && !uploadData.videoPath) {
      return {
        success: true,
        message: `No video found...`,
      };
    }


    let date;
    if (uploadData.schedule) {
      date = uploadData.schedule.toISOString().split('.')[0] + 'Z';
    } else {
      date = undefined;
    }

    const data = {
      id: uploadData.draftId,
      title: uploadData.title,
      desc: uploadData.description,
      privacyStatus: uploadData.privacyStatus,
      schedule: date,
      platform_id: uploadData.platformId,
      postId: uploadData.postId,
      uploadTo: uploadData.uploadTo,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      uploadType: uploadData.uploadType,
    };
    try {
      const response = await axios.post(BACKEND_API_URL + '/api/google/upload_video', { data }, { withCredentials: true });
      if (response.status === 201)
        return {
          success: true,
          message: `Your content has been uploaded to ${uploadData.uploadTo}!`,
        };
    } catch (error) {
      if (error) {
        handleErrorUpload(data);
        return {
          success: false,
          message: `An error occurred while uploading to ${uploadData.uploadTo}!`,
        };
      }
    }
  };

  const uploadToTiktok = async (uploadData) => {
    let privacy = '';
    if (uploadData.privacyStatus === "private") {
      privacy = "SELF_ONLY"
    } else if (uploadData.privacyStatus === "public") {
      privacy = "PUBLIC_TO_EVERYONE"
    }

    const data = {
      id: uploadData.draftId,
      postId: uploadData.postId,
      description: uploadData.description,
      privStatus: privacy,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      platform_id: uploadData.platformId,
      allowComment: uploadData.commentEnabled,
      allowDuet: uploadData.duetEnabled,
      allowStitch: uploadData.stitchEnabled,
      selfBrand: uploadData.isOwnBrand,
      uploadTo: uploadData.uploadTo,
      contentBrand: uploadData.isBranded,
      uploadType: uploadData.uploadType,
    };
    try {
      const response = await axios.post(BACKEND_API_URL + '/api/tiktok/init_upload', { data }, { withCredentials: true });
      if (response.status === 201)
        return {
          success: true,
          message: `Your content has been uploaded to ${uploadData.uploadTo}!`,
        };
    } catch (error) {
      if (error) {
        handleErrorUpload(data);
        return {
          success: false,
          message: `An error occurred while uploading to ${uploadData.uploadTo}!`,
        };
      }
    }
  };

  const uploadToInstagram = async (uploadData) => {
    if (!localVideoPath && !uploadData.videoPath) {
      return {
        success: true,
        message: `No video found...`,
      };
    }


    let endpoint = '';

    if (getFileType(uploadData.uploadType) === 'video') {
      if (uploadData.uploadType === 'STORIES') uploadData.uploadType = 'STORIES'
      else uploadData.uploadType = 'REELS'

      endpoint = '/api/instagram/upload_video'
    }
    if (getFileType(uploadData.uploadType) === 'image') {
      endpoint = '/api/instagram/upload_image'
    }

    const data = {
      id: uploadData.draftId,
      description: uploadData.description,
      platform_id: uploadData.platformId,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      uploadType: uploadData.uploadType,
      postId: uploadData.postId,
      uploadTo: uploadData.uploadTo,
    };

    try {
      if (getFileType(uploadData.uploadType) === 'image') {
        const { aspectRatio, width } = await checkImagePropertie(data.firebasePath || data.video_path);
        console.log(aspectRatio);
        console.log(width);

        // Aspect ratio must be between 0.8 and 1.91
        if (aspectRatio < 0.8 || aspectRatio > 1.91) {
          return {
            success: false,
            message: `The image aspect ratio for instagram must be between 4:5 (0.8) and 1.91:1 (1.91). Your image has an aspect ratio of ${aspectRatio.toFixed(2)}.`,
          };
        }

        // Width must be between 320 and 1440 pixels
        if (width < 320 || width > 1440) {
          return {
            success: false,
            message: `The image width must be between 320px and 1440px. Your image width is ${width}px.`,
          };
        }
      }

      const response = await axios.post(BACKEND_API_URL + endpoint, { data }, { withCredentials: true });
      if (response.status === 201)
        return {
          success: true,
          message: `Your content has been uploaded to ${uploadData.uploadTo}!`,
        };
    } catch (error) {
      console.log(error);

      if (error) {
        handleErrorUpload(data);
        return {
          success: false,
          message: `An error occurred while uploading to ${uploadData.uploadTo}!`,
        };
      }
    }
  };

  const uploadToFacebook = async (uploadData) => {
    const data = {
      id: uploadData.draftId,
      title: uploadData.title,
      description: uploadData.description,
      platform_id: uploadData.platformId,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      postId: uploadData.postId,
      uploadTo: uploadData.uploadTo,
      uploadType: uploadData.uploadType,
    };

    let endpoint = '';

    if (getFileType(uploadData.uploadType) === 'video') {
      if (uploadData.uploadType === 'reel') {
        endpoint = '/api/facebook/upload_reel';
      } else {
        endpoint = '/api/facebook/upload_video';
      }
    }
    else {
      endpoint = '/api/facebook/upload_post';
    }

    try {
      const response = await axios.post(
        BACKEND_API_URL + endpoint,
        { data },
        { withCredentials: true }
      );

      if (response.status === 201) {
        return {
          success: true,
          message: `Your content has been uploaded to ${uploadData.uploadTo}!`,
        };
      }
    } catch (error) {
      console.log(error);
      handleErrorUpload(data);
      return {
        success: false,
        message: `An error occurred while uploading to ${uploadData.uploadTo}!`,
      };
    }
  };


  const handleErrorUpload = async (data) => {
    try {
      // Upload error to drafts
      const res = await axios.post(BACKEND_API_URL + '/api/auth/user/uploads/error', data, { withCredentials: true });
      if (res.status !== 201) return;

      // Fetch user settings
      const userSettingsResponse = await axios.get(BACKEND_API_URL + '/api/auth/user/settings', { withCredentials: true });
      if (userSettingsResponse.status !== 201) return;

      const userSettings = userSettingsResponse.data;
      // Check if notifications are enabled
      if (userSettings.notifications?.isActive && userSettings.notifications?.errorNotifications) {
        const emailHtml = render(<VideoErrorUpload upload={data} />);
        await sendEmail('Important: Video Upload Failed on SocialFlick', emailHtml);
      }
    } catch (error) {
      console.log(error);
    }

    // Log out from social
    if (data.platform_id === undefined) return;
    const platform_id = data.platform_id;
    try {
      await axios.post(BACKEND_API_URL + '/api/auth/user/social/error', { platform_id }, { withCredentials: true });
    } catch (error) {
      console.log(error);
    }
  };

  const saveToDraft = async (isSchedule = false, uploadData) => {
    let thumb = uploadData.thumbnail;

    if (thumb && typeof thumb !== 'string') {
      thumb = await uploadFileToFirebase(uploadData.thumbnail, 'img')
    }

    if (uploadData.status === 'uploaded') {
      //if its already uploaded history video, create new video instead of editing the history.
      uploadData.postId = uniquePostId;
      uploadData.draftId = null;
    }


    const data = {
      id: uploadData.draftId || undefined,
      post_id: uploadData.postId,
      platform_id: uploadData.platformId,
      title: uploadData.title,
      description: uploadData.description,
      uploadType: uploadData.uploadType || '',
      privacy_status: uploadData.privacyStatus || 'private',
      video_path: localVideoPath || formData.videoPath,
      firebase_path: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      status: isSchedule === true ? 'scheduled' : 'draft',
      uploadTo: uploadData.uploadTo,
      schedule: isSchedule === true ? uploadData.schedule : null
    };

    try {
      await axios.post(BACKEND_API_URL + '/api/auth/user/drafts', { data }, { withCredentials: true });
      if (isSchedule === true) {
        toast.success('Your post has been scheduled!');
        if (typeof closeScheduleModal === 'function') {
          closeScheduleModal();
        } else {
          navigate('/dashboard/calendar');
        }
      } else if (uploadData.draftId) {
        toast.success('Your post has been updated!');
        navigate('/dashboard');
      } else {
        toast.success('Your post has been saved to your drafts!');
        navigate('/dashboard');
      }

    } catch (error) {
      if (isSchedule === true) {
        toast.error('Failed to schedule your post, please try again!');
      } else {
        toast.error('Failed to save your post to drafts, please try again!');
      }
    }
  };



  const handleScheduleUpload = (uploadData, scheduleDate) => {
    if (uploadData.uploadTo === 'draft') return;
    saveToDraft(true, uploadData);
  };

  // UploadForm Action, and platforms objects data
  const handleFormAction = async (action, data) => {
    if (data.length === 0) {
      toast.warning('There has been no changed made!');
      return;
    }
    if (data && data.length > 0) {
      for (const platform of data) {
        if (action === 'upload') {
          handleUploadButtonClick(platform);
        } else if (action === 'schedule') {
          handleScheduleUpload(platform);
        } else if (action === 'draft') {
          saveToDraft(false, platform);
        }
      }
    }
  };


  const handleFileSelect = (firebaseVideoPath, firebaseThumbnail) => {
    const localPath = extractFolderAndFileName(firebaseVideoPath);
    setLocalVideoPath(localPath)
    setFirebaseVideoPath(firebaseVideoPath);
    setFirebaseThumbPath(firebaseThumbnail);
  };

  return (
    <div className='flex flex-col justify-center  w-full px-2 md:px-4'>
      <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4 items-stretch">
        <div className="left-side-container w-full md:w-2/3">
          <UploadForm
            draftFormData={formData}
            onFormAction={handleFormAction}
            video={{ localVideo: localVideoPath, firebasePath: firebaseVideoPath, thumbnail: firebaseThumbPath }}
            uploadType={uploadType}
          />
        </div>

        <div className="right-side-container w-full md:w-1/3">
          <VideoPreview draftVideo={firebaseVideoPath} draftThumb={firebaseThumbPath} onFileSelect={handleFileSelect} uploadType={uploadType} />
        </div>
      </div>
    </div>
  );
};
export default VideoUploader;