export const checkImagePropertie = (imageUrl) => {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            const aspectRatio = width / height;
            resolve({ width, height, aspectRatio });
        };

        img.onerror = function () {
            reject(new Error('Failed to load image at ' + imageUrl));
        };

        img.src = imageUrl;
    });
}

