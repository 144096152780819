import React, { useState } from 'react';
import axios from 'axios';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const InstagramLoginButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAuthClick = () => {
    // Call the authorize function to generate the authorization URL
    setIsLoading(true);
    axios.get(BACKEND_API_URL + '/api/instagram/oauth', { withCredentials: true })
      .then(response => {
        window.open(response.data, '_blank');
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <button className="flex items-center w-full justify-between h-[70px] bg-gradient-to-r from-yellow-400 via-pink-500 to-purple-600 hover:from-yellow-500 hover:via-pink-600 hover:to-purple-700" onClick={handleAuthClick} disabled={isLoading} data-scope="public_profile,email,instagram_basic">
      {isLoading ? 'Loading...' : (
        <>
          <div className="mr-3 w-12 h-12 bg-white rounded-full flex items-center justify-center">
            <img
              src={'../socials/instagram-logo.svg'}
              alt="Instagram Logo"
              className="w-8 h-8"
            />
          </div>
          <p className='button-text-tiktok'>
            {'Instagram Business'}
          </p>
        </>
      )}
      <div></div>
    </button>
  );
}

export default InstagramLoginButton;