// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDpjEdKSH9qFKAqE5QImMZgnSO5OMzO4uY",
  authDomain: "socialshare-6946b.firebaseapp.com",
  projectId: "socialshare-6946b",
  storageBucket: "socialshare-6946b.appspot.com",
  messagingSenderId: "568132719162",
  appId: "1:568132719162:web:9936f41a0128e4a98adb03",
  measurementId: "G-49RNHC2YXJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);