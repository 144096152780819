import React from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchInput = ({ value, onChange }) => {
    return (
        <label
            htmlFor="Search"
            className="relative flex overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600"
        >
            <FaSearch className="text-gray-400" />

            <input
                type="text"
                id="Search"
                value={value}
                onChange={onChange}
                placeholder="Search templates..."
                className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
            />
            <span
                className="absolute start-7 bottom-3 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"
            >
                Search
            </span>

        </label>
    );
};

export default SearchInput;
