import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import useLocalStorage from 'use-local-storage';
import axios from 'axios';
import ResetPassword from '../../components/Auth/ResetPassword/ResetPassword';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const ConfirmEmail = () => {
    const { token } = useParams();
    const [responseData, setResponseData] = useState(null)
    const [isError, setIsError] = useState(false)
    const hasRunOnceRef = useRef(false)


    useEffect(() => {
        if (!hasRunOnceRef.current) {
            hasRunOnceRef.current = true;

            // Your code here, it will run only once on mount
            (async () => {
                if (token) {
                    try {
                        const response = await axios.get(BACKEND_API_URL + '/api/auth/user/confirm/' + token, {
                            withCredentials: true,
                        });
                        setResponseData(response.data.message);
                        setIsError(false);
                    } catch (error) {
                        if (error.response) {
                            if (error.response.data) {
                                setResponseData(error.response.data.error);
                                setIsError(true);
                            }
                        }
                    }
                }
            })();
        }
    }, [])


    return (
        <div className="min-h-screen flex justify-center items-center justify-center w-full">
            <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full space-y-4">
                <div className="flex flex-col items-center justify-center text-green-500">

                    {isError ? <FiXCircle className="text-5xl" color='red' /> : <FiCheckCircle className="text-5xl" />}

                </div>
                <p className={`!text-2xl !font-semibold !text-center ${isError ? '--error-message' : '--success-message'}`}>
                    {responseData}
                </p>
                <div className='flex justify-center'>
                    {isError ? (
                        <div className='flex flex-col justify-center items-center'>
                            <p>Go to your settings and try again</p>
                            <Link
                                to="/dashboard/settings"
                                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-300"
                            >
                                Settings
                            </Link>                        </div>

                    ) : (
                        <Link
                            to="/dashboard"
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-300"
                        >
                            Dashboard
                        </Link>
                    )}
                </div>

            </div>
        </div>
    );
};

export default ConfirmEmail;