import React, { useState } from 'react';
import axios from 'axios';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const YoutubeLoginButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAuthClick = () => {
    // Call the authorize function to generate the authorization URL
    setIsLoading(true);
    axios.post(BACKEND_API_URL + '/api/google/oauth', {}, { withCredentials: true })
      .then(response => {
        window.open(response.data, '_blank');
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };



  return (
    <><button className="flex items-center w-full justify-between h-[70px]" onClick={handleAuthClick} disabled={isLoading}>
      {isLoading ? 'Loading...' : (
        <>
          <div className="mr-3 w-12 h-12 bg-white rounded-full flex items-center justify-center">
            <img
              src={'../socials/youtube-logo.svg'}
              alt="Instagram Logo"
              className="w-8 h-8"
            />
          </div>
          <p className='button-text-tiktok'>
            {'Youtube'}
          </p>
        </>
      )}
      <div></div>
    </button></>
  );
}
export default YoutubeLoginButton;
