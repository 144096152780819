import React, { useState } from 'react';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'; // For password visibility toggle

const Input = ({ type = "text", name, value, onChange, onPaste, placeholder, Icon }) => {
  const [showPassword, setShowPassword] = useState(false);

  // Toggle for password visibility
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative flex items-center">
      {/* Placeholder icon */}
      {Icon && (
        <Icon className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
      )}

      {/* Input field */}
      <input
        type={type === 'password' && showPassword ? 'text' : type}
        name={name}
        value={value}
        onChange={onChange}
        onPaste={onPaste}
        autoComplete="on"
        placeholder={placeholder}
        required
        className={`pl-10 w-full text-black border-0 border-b-2 border-gray-300 min-h-[40px] py-1.5 transition-all duration-300 ease-in-out focus:ring-0 focus:outline-0 focus:border-indigo-600 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
      />

      {/* Show/hide password icon for password fields */}
      {type === 'password' && (
        <div className="absolute right-2 cursor-pointer" onClick={toggleShowPassword}>
          {showPassword ? <AiFillEye size={20} /> : <AiFillEyeInvisible size={20} />}
        </div>
      )}
    </div>
  );
};

export default Input;
