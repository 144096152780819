import React, { useState, useEffect, useRef } from 'react';
import { CgProfile } from "react-icons/cg";
import Socials from '../../pages/Protected/Socials/Socials';
import axios from 'axios';
import VideoUploader from '../VideoUploader/VideoUploader';
import { RiAdvertisementFill } from 'react-icons/ri';
import { FaFacebookF, FaGoogle, FaInstagram, FaRegLightbulb, FaSearch, FaTwitter, FaUserFriends, FaYoutube } from 'react-icons/fa';
import { IoShareSocialSharp } from "react-icons/io5";
import { MdOutlineCloudUpload } from 'react-icons/md';
import { BsTwitterX } from 'react-icons/bs';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
const username = localStorage.getItem('username');

const Onboarding = ({ onClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const broadcastChannel = new BroadcastChannel('socialsChannel');
    const [steps, setSteps] = useState([
        { label: 'Your Role', component: <Step1 />, completed: false, icon: <CgProfile size={30} color='white' /> },
        { label: 'Where did you find us?', component: <Step2 />, completed: false, icon: <FaSearch size={30} color='white' /> },
        { label: 'Connect Socials', component: <Step3 />, completed: false, icon: <IoShareSocialSharp size={30} color='white' /> },
        { label: 'Upload A Video', component: <Step4 />, completed: false, icon: <MdOutlineCloudUpload size={30} color='white' /> }
    ]);
    const [selectedRole, setSelectedRole] = useState("");
    const [selectedHow, setSelectedHow] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [socials, setSocials] = useState([]);
    const hasRunOnceRef = useRef(false)

    const handleNext = () => setActiveStep(prevStep => prevStep + 1);
    const handleBack = () => setActiveStep(prevStep => prevStep - 1);

    useEffect(() => {
        if (!hasRunOnceRef.current) {
            hasRunOnceRef.current = true;
            getUsersOnboarding();
            getUsersSocials();

            broadcastChannel.onmessage = event => {
                if (event.data === 'callbackLogin') {
                    getUsersSocials();
                }
            };
        }
    }, []);

    const handleClose = () => {
        onClose();
    };

    const getUsersOnboarding = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/settings', {
                withCredentials: true, // Include this option if you need cookies
            });
            if (response.status === 201) {
                if (response.data && response.data.onboarding.completed === true) {
                    const onboarding = response.data.onboarding;
                    setSelectedRole(onboarding.user_role);
                    console.log(onboarding);
                    setSelectedHow(onboarding.user_discover);

                    setSteps(prevSteps => prevSteps.map(step => ({
                        ...step,
                        completed: true
                    })));
                }
            }
        } catch (error) {
            console.log(error);
        }
    };



    const handleStepClick = (index) => {
        setActiveStep(index);
    };

    const handleStep1Submit = (activeRole) => {
        if (activeRole !== '') {
            handleNext();
            setSelectedRole(activeRole)
            setSteps(prevSteps => {
                const updatedSteps = [...prevSteps];
                updatedSteps[0].completed = true;
                return updatedSteps;
            });
        }
    };

    const handleStep2Submit = async (activeHow) => {
        if (activeHow !== '') {
            handleNext();
            setSelectedHow(activeHow)
            setSteps(prevSteps => {
                const updatedSteps = [...prevSteps];
                updatedSteps[1].completed = true;
                return updatedSteps;
            });
        }

        //format for onboarding
        const onboarding = {};
        onboarding.onboarding = {
            user_role: selectedRole,
            user_discover: activeHow,
            completed: true
        };

        try {
            const response = await axios.post(BACKEND_API_URL + '/api/auth/user/settings', onboarding, {
                withCredentials: true,
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };


    const handleStep3Submit = () => {
        handleNext();
        setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[2].completed = true;
            return updatedSteps;
        });

        //setIsModalOpen(false);
    };

    const handleStep4Submit = async () => {
        handleNext();
        setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[3].completed = true;
            return updatedSteps;
        });
        setIsModalOpen(false);
        onClose();
    };

    async function getUsersSocials() {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/social_media_tokens', {
                withCredentials: true,
            });
            setSocials(response.data);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    const finishOnboarding = () => {
        setIsModalOpen(false);
        onClose();
    }
    return (
        <>
            {isModalOpen && ( // Render the modal only if isModalOpen is true
                <div className="fixed flex items-center justify-center modal-overlay">
                    <div className="flex flex-col bg-white rounded-lg overflow-auto shadow-xl transform transition-all w-[80%] h-[800px] ">
                        {/* Close button */}
                        <div className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 z-10" onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        {/* TOP SIDE HEADER */}
                        <div className='flex flex-col items-center py-5 border-b-2 mx-2 relative'>
                            <div className='text-center'>
                                <p className='text-[30px] font-bold'>Welcome to SocialFlick{username && (
                                    <span>, {username}</span>
                                )}</p>
                                <p className='text-sm'>Let us make your onboarding easier to SocialFlick. Navigate through the onboarding step, to get the fastest start up.</p>
                                <p className='text-sm italic'>Your answer helps us personalize and optimize your experience.</p>
                            </div>
                            <div className='gap-2 mt-5 px-5 hidden md:flex'>
                                {steps.map((step, index) => (
                                    <div key={index} className='flex flex-col gap-2'>
                                        <div className='flex items-center'>
                                            <div className={`p-3 rounded-full cursor-pointer transform hover:scale-105 ${step.completed ? ' bg-gradient-to-r from-blue-400 to-blue-700' : 'bg-gray-300'}`} onClick={() => handleStepClick(index)}>
                                                {step.icon}
                                            </div>
                                            <span className={`line w-[100px] h-[5px] ${step.completed ? 'bg-gradient-to-r from-blue-700 to-blue-300' : 'bg-gradient-to-r from-gray-500 to-gray-300'}`}></span>
                                        </div>
                                        <p className='text-sm'>{step.label}</p>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* BOTTOM SIDE - CONTENT - STEP PAGES */}
                        <div className='wrapper border-red-500 w-full h-full'>
                            {/* Render current step component */}
                            {activeStep === 0 && <Step1 onSubmit={handleStep1Submit} active={selectedRole} />}
                            {activeStep === 1 && <Step2 onSubmit={handleStep2Submit} active={selectedHow} onBack={handleBack} />}
                            {activeStep === 2 && <Step3 onSubmit={handleStep3Submit} onBack={handleBack} socials={socials} finishOnboarding={finishOnboarding} />}
                            {activeStep === 3 && <Step4 onSubmit={handleStep4Submit} onBack={handleBack} finishOnboarding={finishOnboarding} />}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

const Step1 = ({ onSubmit, active }) => {
    const [activeRole, setActiveRole] = useState(active || '');

    useEffect(() => {
        setActiveRole(active || '');
    }, [active]);

    const handleRoleClick = (role) => {
        setActiveRole(role.alt);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(activeRole); // Pass the activeRole to the onSubmit function
    };

    const [roles, setRoles] = useState([
        {
            label: "Creator / Influencer",
            image: "./onboarding/influencer.png",
            alt: "Influencer",
            description: "You manage your own social media channels and work on your own brand or for entertainment."
        },
        {
            label: "Startup / Small Business",
            image: "./onboarding/shop.png",
            alt: "Startup",
            description: "You have started a startup business or are running a small business."
        },
        {
            label: "Freelancer",
            image: "./onboarding/freelance.png",
            alt: "Freelancer",
            description: "You manage social media for more than one individual clients other than yourself."
        },
        {
            label: "Agency",
            image: "./onboarding/enterprise.png",
            alt: "Agency",
            description: "You manage multiple social media accounts simultaneously as part of an agency."
        },
        {
            label: "Large Business",
            image: "./onboarding/company.png",
            alt: "Business",
            description: "You represent a large corporation (over 50 employees) with complex social media management needs, overseeing multiple accounts."
        },
        {
            label: "Other",
            image: "./onboarding/other.png",
            alt: "Other",
            description: "None of the above."
        }
    ]);

    return (
        <form onSubmit={handleSubmit} className='h-[95%]'> {/* Add onSubmit event to the form */}
            <div className='flex flex-col gap-2 w-[80%] mx-auto h-full'>
                <p className='capitalize italic text-sm'>STEP 1 OF 4</p>
                <p className='text-[25px] font-semibold'>Which role best describes your role?</p>

                <div className='grid gap-3 mt-5 grid-cols-2 md:grid-cols-3'>
                    {/* Render role options */}
                    {roles.map((role, index) => (
                        <div key={index} className={`flex flex-col items-center justify-between py-2 border-2 border-gray-300 hover:border-blue-500 cursor-pointer ${role.alt === activeRole ? 'bg-blue-100' : ''}`} onClick={() => handleRoleClick(role)}>
                            <div className='w-20'>
                                <img src={role.image} alt={role.alt} />
                            </div>
                            <div className='font-semibold text-center'>{role.label}</div>
                            <div className='text-sm w-[90%] text-center'>{role.description}</div>
                        </div>
                    ))}
                </div>
                <div className='flex-grow'></div>
                <div className='self-end'>
                    <button type="submit" className={`bg-blue-500 ${activeRole === '' ? 'disabled bg-gray-500 cursor-not-allowed hover:bg-gray-500' : ''}`} disabled={activeRole === ''}>Next</button>
                </div>
            </div>
        </form>
    )
}

const Step2 = ({ onSubmit, active, onBack }) => {
    const hows = [
        { title: 'Facebook', icon: <FaFacebookF color='blue' /> },
        { title: 'Youtube', icon: <FaYoutube color='red' /> },
        { title: 'Twitter', icon: <BsTwitterX color='black' /> },
        { title: 'Instagram', icon: <FaInstagram color='#cd486b' /> },
        { title: 'Google', icon: <FaGoogle color='#FBBC05' /> },
        { title: 'Referral', icon: <FaUserFriends className='text-blue-800' /> },
        { title: 'Advertisement', icon: <RiAdvertisementFill color='blue' /> },
        { title: 'Other', icon: <FaRegLightbulb className='text-yellow-200' /> }
    ];

    const [activeHow, setActiveHow] = useState(active);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(activeHow); // Pass the activeRole to the onSubmit function
    };

    const handleClick = (how) => {
        setActiveHow(how);
    };

    return (
        <form onSubmit={handleSubmit} className='h-[95%]'> {/* Add onSubmit event to the form */}
            <div className='flex flex-col w-[80%] mx-auto h-full'>
                <p className='capitalize italic text-sm'>STEP 2 OF 4</p>
                <p className='text-[25px] font-semibold'>How did you discover SocialFlick?</p>

                <div className='grid gap-3 mt-5 grid-cols-2 md:grid-cols-3'>
                    {hows.map((role, index) => (
                        <div key={index} className={`flex flex-col items-center justify-between py-2 border-2 border-gray-300 hover:border-blue-500 cursor-pointer ${role.title === activeHow ? 'bg-blue-100' : ''}`} onClick={() => handleClick(role.title)}>
                            <div className=''>
                                {role.icon}
                            </div>
                            <div>{role.title}</div>
                        </div>
                    ))}
                </div>

                <div className='flex-grow'></div>
                <div className='flex justify-between'>
                    <button type="button" onClick={onBack} className="bg-blue-500">Back</button>
                    <button type="submit" className={`bg-blue-500 ${activeHow === '' ? 'disabled bg-gray-500 cursor-not-allowed hover:bg-gray-500' : ''}`} disabled={activeHow === ''}>Next</button>
                </div>
            </div>
        </form>
    )
}

const Step3 = ({ onSubmit, onBack, socials, finishOnboarding }) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <form onSubmit={handleSubmit} className='h-[95%]'> {/* Add onSubmit event to the form */}
            <div className='flex flex-col w-[80%] mx-auto h-full'>
                <p className='capitalize italic text-sm'>STEP 3 OF 4</p>
                <p className='text-[25px] font-semibold'>Connect to your social media accounts</p>
                <Socials value={'dashboard'} />
                <div className='flex-grow'></div>

                <div className='flex justify-between'>
                    <button type="button" onClick={onBack} className="bg-blue-500">Back</button>
                    <div className='flex items-end gap-2'>
                        <div className={`text-sm hover:underline cursor-pointer hover:text-blue-300 text-right`} onClick={finishOnboarding}>Finish onboarding</div>
                        <button type="submit" className={``}>{socials.length > 0 ? 'Next' : 'Skip'}</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

const Step4 = ({ onSubmit, onBack, finishOnboarding }) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };
    return (
        <form onSubmit={handleSubmit} className='h-[95%]'> {/* Add onSubmit event to the form */}
            <div className='flex flex-col w-[80%] mx-auto h-full'>
                <p className='capitalize italic text-sm'>STEP 4 OF 4</p>
                <p className='text-[25px] font-semibold'>Upload a Video</p>
                <VideoUploader />

                <div className='flex-grow'></div>

                <div className='flex justify-between py-10'>
                    <button type="button" onClick={onBack} className="bg-blue-500">Back</button>
                    <div className='flex items-end gap-3'>
                        <div className={`text-sm hover:underline cursor-pointer hover:text-blue-300 text-right`} onClick={finishOnboarding}>Finish onboarding</div>
                        <button type="submit" className={`bg-blue-500 `}>Skip</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Onboarding;
