import {
    Body,
    Button,
    Container,
    Head,
    Hr,
    Html,
    Img,
    Preview,
    Section,
    Text,
} from "@react-email/components";
import * as React from "react";


interface DeleteAccountUserProps {
    userInfo: {
        name: string;
        email: string;
    };
    reason: string;
}

export const DeleteAccountUser = ({
    userInfo,
    reason,
}: DeleteAccountUserProps) => (
    <Html>
        <Head />
        <Preview>
            We're sad to see you go
        </Preview>
        <Body style={main}>
            <Container style={container}>
                <Img
                    src={`https://app.socialflick.io/logo/logo-vertical-black.png`}
                    width={"50%"}
                    alt="SocialFlick"
                    style={logo}
                />
                <Text style={paragraph}>We are sad to see you leave 😢</Text>
                <Text style={paragraph}>Your account will be deleted within 30 days. Login as usual to recover your account.</Text>
                <Section style={btnContainer}>
                    <Button style={button} href={'https://app.socialflick.io/login'}>
                        Reactivate
                    </Button>
                </Section>
                <Text style={paragraph}>If you have any questions or need assistance, please contact our support team.</Text>
            </Container>
        </Body>
    </Html>
);

DeleteAccountUser.PreviewProps = {
    userInfo: {
        name: '',
        email: ''
    },
    reason: '',
} as DeleteAccountUserProps;

export default DeleteAccountUser;

const main = {
    backgroundColor: "#ffffff",
    fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
};

const container = {
    margin: "0 auto",
    padding: "20px 0 48px",
};

const logo = {
    margin: "0 auto",
};

const paragraph = {
    fontSize: "16px",
    lineHeight: "26px",
};


const btnContainer = {
    textAlign: "center" as const,
};

const button = {
    backgroundColor: "#5F51E8",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "16px",
    textDecoration: "none",
    textAlign: "center" as const,
    display: "block",
    padding: "12px",
};